import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { actions, selectors, store } from '../../store'

import Loader from '../../UIComponents/Loader'
import { hardRedirect } from '../../utils/location'

function SSOSignOut ({ backend, business, ssoSignOutRedirect }) {
  const context = useContext(store)
  const [domesticSessionRemoving, setDomesticSessionRemoving] = useState(false)
  const { ok, error } = selectors.ssoSignOut(context)
  const { dispatch } = context

  useEffect(() => {
    if (ok) {
      hardRedirect(ssoSignOutRedirect)
    }
  }, [ssoSignOutRedirect, ok])

  useEffect(() => {
    if (error) {
      hardRedirect(ssoSignOutRedirect)
    }
  }, [ssoSignOutRedirect, error])

  useEffect(() => {
    async function signOut () {
      console.log('ssoSignOutRedirect: ', ssoSignOutRedirect)
      setDomesticSessionRemoving(true)
      await actions.ssoSignOut({ dispatch, business, backend })
      setDomesticSessionRemoving(false)
      window._sva.destroyVisitor(function () {
        console.log('Survey session storage and localstorage data distroyed')
      })
    }

    signOut()
  }, [dispatch, backend, business, ssoSignOutRedirect])

  return (
    <section className='sso-sign-out'>
      <Loader loading={domesticSessionRemoving} size='modal' />
    </section>
  )
}

SSOSignOut.propTypes = {
  backend: PropTypes.string.isRequired,
  business: PropTypes.string.isRequired,
  ssoSignOutRedirect: PropTypes.string.isRequired
}

export default SSOSignOut
