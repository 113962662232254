import React from 'react'
import PropTypes from 'prop-types'
import { format } from '../../utils/date'
import { DownloadImageButtom } from '../../UIComponents/Button'
import './ProgrammeDigibadgeDownload.scss'

function getProgrammesCompletionFormatedDate ({ programme }) {
  const updatedProgramme = { ...programme, completionDate: format({ date: `${programme.completionDate}`, preset: 'short' }) }
  return updatedProgramme
}

function ProgrammeDigibadgeDownload ({ title, programmeDigibadgeDownloadButtonText, programme, digibadges }) {
  let formatedProgramme
  if (programme?.completionDate) {
    formatedProgramme = getProgrammesCompletionFormatedDate({ programme })
  }

  let hasDigibadge = false
  let filteredDigibadge
  const values = digibadges.filter(digibadge => {
    const programCompletionYear = formatedProgramme?.completionDate?.split('/').filter(item => item.length === 4)
    console.log('Got year only of programCompletionYear: ', parseInt(programCompletionYear[0]), digibadge?.year)
    if (parseInt(programCompletionYear[0]) === digibadge?.year) {
      hasDigibadge = true
      filteredDigibadge = digibadge
    }
    return true
  })

  console.log(`Inside digibadge component${hasDigibadge} & ${values}`)
  if (hasDigibadge) {
    console.log(`completion date ${programme?.completionDate} has digibadges : ${digibadges.length}`)
    const disabled = false
    const level = 'primary'
    const href = filteredDigibadge.url // url of file
    const download = filteredDigibadge.originalFilename

    return (
      <section className='programme-digibadge-download'>
        <div className='programme-digibadge-download-container'>
          <div><h2 className='programme-digibadge-download-title'>{title}</h2></div>
          <DownloadImageButtom level={level} disabled={disabled} url={href} name={download}>
            {programmeDigibadgeDownloadButtonText}
          </DownloadImageButtom>
        </div>
        <hr></hr>
      </section>
    )
  } else {
    return <></>
  }
}
ProgrammeDigibadgeDownload.propTypes = {
  title: PropTypes.string.isRequired,
  programmeDigibadgeDownloadButtonText: PropTypes.string.isRequired,
  digibadges: PropTypes.arrayOf(PropTypes.shape({
    extension: PropTypes.string.isRequired,
    mimeType: PropTypes.string.isRequired,
    originalFilename: PropTypes.string.isRequired,
    size: PropTypes.number,
    url: PropTypes.string,
    year: PropTypes.number
  }).isRequired),
  programme: PropTypes.shape({
    name: PropTypes.string.isRequired,
    salesForceProgrammeID: PropTypes.string.isRequired,
    completionDate: PropTypes.string,
    digibadges: PropTypes.arrayOf(PropTypes.shape({
      extension: PropTypes.string.isRequired,
      mimeType: PropTypes.string.isRequired,
      originalFilename: PropTypes.string.isRequired,
      size: PropTypes.number,
      url: PropTypes.string,
      year: PropTypes.number
    }))
  }).isRequired
}
export default ProgrammeDigibadgeDownload
