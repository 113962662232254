import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { hardRedirect } from '../../utils/location'

function SSOSignIn ({ ssoSignInRedirect }) {
  useEffect(() => {
    hardRedirect(ssoSignInRedirect)
  }, [ssoSignInRedirect])

  return null
}

SSOSignIn.propTypes = {
  backend: PropTypes.string.isRequired,
  business: PropTypes.string.isRequired,
  ssoSignInRedirect: PropTypes.string.isRequired
}

export default SSOSignIn
