import React from 'react'
import PropTypes from 'prop-types'

import './HeaderTitle.scss'

function HeaderTitle ({ title }) {
  return (
    <header className='header-title'>
      <h1 className='header-title-title'>{title}</h1>
    </header>
  )
}

HeaderTitle.propTypes = {
  business: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default HeaderTitle
