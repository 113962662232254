import React from 'react'
import PropTypes from 'prop-types'

import './IntroContainer.scss'

function IntroContainer ({ children, ...props }) {
  return <section className='intro-container' {...props}>{children}</section>
}

IntroContainer.propTypes = {
  children: PropTypes.node
}

export default IntroContainer
