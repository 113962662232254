import React from 'react'
import PropTypes from 'prop-types'

import { FileIcon } from '../Icon'
import Loader from '../Loader'

import { openWindow } from '../../utils/location'

import './FileList.scss'

function openLink ({ event, href }) {
  event.preventDefault()
  openWindow(href)
}

function FileListItemLink ({ href, name, type, removeOption, removeLabel, removeHandler, documentId, turncatName }) {
  return (
    <li className='ui-component-file-list-item'>
      <div className='ui-component-file-list-item-icon-container'>
        <FileIcon type={type} title={type} className='ui-component-file-list-item-icon' />
      </div>
      {turncatName ? <a href={href} title={name} onClick={(event) => openLink({ event, href })} className='ui-component-file-list-item-link ui-component-file-name-shorten'>{name}</a> : <a href={href} onClick={(event) => openLink({ event, href })} className='ui-component-file-list-item-link'>{name}</a>}
      {removeOption &&
      <button type='button' className='ui-component-remove-button' level = 'primary' onClick={() => fileRemoveHandler({ removeHandler, documentId })} value={removeLabel}>
        {removeLabel}
      </button> }
    </li>
  )
}

function FileListItemLoading ({ name, type }) {
  return (
    <li className='ui-component-file-list-item'>
      <div className='ui-component-file-list-item-icon-container'>
        <FileIcon type={type} title={type} className='ui-component-file-list-item-icon' />
      </div>
      <span className='ui-component-file-list-item-name'>{name} <Loader loading size='inline' /></span>
    </li>
  )
}

function fileRemoveHandler ({ removeHandler, documentId }) {
  removeHandler(documentId)
}

function FileList ({ className = '', files, removeOption = false, removeLabel = '', clickHandler = () => { }, turncatName = false }) {
  if (!files.length) {
    return null
  }

  const listClass = `ui-component-file-list ${className}`.trim()

  const fileList = files.map(({ href, loading = false, name, type, documentId = '' }, index) => loading
    ? <FileListItemLoading loading name={name} type={type} key={index} />
    : <FileListItemLink turncatName={turncatName} href={href} name={name} type={type} key={index} removeOption={removeOption} removeLabel={removeLabel} removeHandler={clickHandler} documentId={documentId}/>
  )

  return (
    <ul className={listClass}>
      {fileList}
    </ul>
  )
}

FileList.propTypes = {
  className: PropTypes.string,
  removeOption: PropTypes.bool,
  removeLabel: PropTypes.string,
  clickHandler: PropTypes.func,
  files: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    loading: PropTypes.bool,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    documentId: PropTypes.string
  })),
  turncatName: PropTypes.bool
}

export default FileList
