import React from 'react'
import PropTypes from 'prop-types'

import MarkdownStandard from '../../UIComponents/MarkdownStandard'

import './CourseBodyPreCourseworkInformation.scss'

function CourseBodyPreCourseworkInformation ({ course: { preCourseworkInformation } }) {
  return (
    <div className='course-body-pre-coursework-information'>
      <MarkdownStandard className='course-body-pre-coursework-information-information'>{preCourseworkInformation}</MarkdownStandard>
    </div>
  )
}

CourseBodyPreCourseworkInformation.propTypes = {
  course: PropTypes.shape({
    preCourseworkInformation: PropTypes.string.isRequired
  })
}

export default CourseBodyPreCourseworkInformation
