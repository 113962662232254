import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

function Head ({ image, title }) {
  return (
    <>
      <Helmet>
        {title && <title>{title}</title>}
      </Helmet>
      <Helmet>
        {title && <meta property='og:title' content={title} />}
        {image && <meta property='og:image' content={image} />}
      </Helmet>
      <Helmet>
        {title && <meta name='twitter:title' content={title} />}
        {image && <meta name='twitter:image' content={image} />}
      </Helmet>
    </>
  )
}

Head.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string
}

export default Head
