import React from 'react'
import PropTypes from 'prop-types'

import Bookmark from '../Bookmark'
import Card from '../Card'
import Icon from '../Icon'
import ViewLink from '../ViewLink'

import * as dateUtils from '../../utils/date'
import * as durationUtils from '../../utils/duration'

import './ContentCard.scss'

function getStyle (image) {
  const backgroundImage = image ? `url(${image})` : 'none'
  return { backgroundImage }
}

function getDate ({ editedDate, publicationDate }) {
  let content
  if (editedDate) {
    const formattedEditDate = dateUtils.format({ date: editedDate, preset: 'short' })
    content = `Last edited on: ${formattedEditDate}`
  } else {
    const formattedPublicationDate = dateUtils.format({ date: publicationDate, preset: 'short' })
    content = `Published on: ${formattedPublicationDate}`
  }
  return <p className='ui-component-content-card-additional-publication-date'>{content}</p>
}

function getTimeToRead ({ formattedTime }) {
  return formattedTime && <>
    <dt className='ui-component-content-card-image-meta-key'>Time to read/watch</dt>
    <dd className='ui-component-content-card-image-meta-value'>
      <Icon className='ui-component-content-card-image-meta-value-clock-icon' icon='clock' prefix='far' title='Time' />
      {formattedTime}
    </dd>
  </>
}

function ContentCardMicro ({ imageStyle, formattedTime, bookmarkIcon, title, editedDate, publicationDate, link, type, isShowTitle = true }) {
  const formattedDate = dateUtils.format({ date: editedDate || publicationDate, preset: 'short' })
  const timeToRead = getTimeToRead({ formattedTime })
  const linkDescription = `Go to ${title}`
  return (
    <Card className='ui-component-content-card-micro' size='micro' link={link}>
      <div className='ui-component-content-card-image-micro' style={imageStyle}>
        <div className='ui-component-content-card-bookmarked-micro'>{bookmarkIcon}</div>
      </div>
      <div className='ui-component-content-card-content-container'>
        {isShowTitle && <dl className='ui-component-content-card-image-meta-micro'>
          <dt className='ui-component-content-card-image-meta-key'>Type</dt>
          <dd className='ui-component-content-card-image-meta-value ui-component-content-card-image-meta-value-type'>{type}</dd>
          {timeToRead}
        </dl>}
        <div className='ui-component-content-card-content-micro'>
          <h3 className='ui-component-content-card-content-title-micro'>{title}</h3>
        </div>
        <div className='ui-component-content-card-additional-micro'>
          {formattedDate}
          <ViewLink className='ui-component-content-card-additional-link' link={link} description={linkDescription} />
        </div>
      </div>
    </Card>
  )
}

function ContentCardSmall ({ imageStyle, formattedTime, bookmarkIcon, title, editedDate, publicationDate, link, type, isShowTitle = true }) {
  const renderedDate = getDate({ editedDate, publicationDate })
  const timeToRead = getTimeToRead({ formattedTime })
  const linkDescription = `Go to ${title}`
  return (
    <Card className='ui-component-content-card-small' size='small' link={link}>
      <div className='ui-component-content-card-image-small' style={imageStyle}>
        {isShowTitle && <dl className='ui-component-content-card-image-meta-small'>
          <dt className='ui-component-content-card-image-meta-key'>Type</dt>
          <dd className='ui-component-content-card-image-meta-value ui-component-content-card-image-meta-value-type'>{type}</dd>
          {timeToRead}
        </dl>}
        <div className='ui-component-content-card-bookmarked-small'>{bookmarkIcon}</div>
      </div>
      <div className='ui-component-content-card-content-small'>
        <h3 className='ui-component-content-card-content-title-small'>{title}</h3>
      </div>
      <div className='ui-component-content-card-additional-small'>
        {renderedDate}
        <ViewLink className='ui-component-content-card-additional-link' link={link} description={linkDescription} />
      </div>
    </Card>
  )
}

function ContentCard ({ bookmarked = false, editedDate, image, link, publicationDate, time, title, type, variant, bookMarkAllowed = false, isShowTitle = true }) {
  const imageStyle = getStyle(image)
  const formattedTime = time ? durationUtils.format({ duration: time }) : ''
  const bookmarkIcon = bookMarkAllowed ? <Bookmark bookmarked={bookmarked} /> : null
  const common = { imageStyle, formattedTime, bookmarkIcon, title, editedDate, publicationDate, link, type, isShowTitle }
  const ContentCardVariant = variant === 'small' ? ContentCardMicro : ContentCardSmall
  return <ContentCardVariant {...common} />
}

ContentCard.propTypes = {
  bookmarked: PropTypes.bool,
  bookMarkAllowed: PropTypes.bool,
  editedDate: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string.isRequired,
  publicationDate: PropTypes.string.isRequired,
  time: PropTypes.number,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['article', 'quiz', 'report', 'resource', 'video']).isRequired,
  variant: PropTypes.oneOf(['normal', 'small']).isRequired
}

export default ContentCard
