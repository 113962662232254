import queryString from 'query-string'

export function origin () {
  return window.location.origin
}

export function openWindow (url) {
  return window.open(url)
}

export function historyPush ({ history, pathname, search = '' }) {
  history.push({ pathname, search })
}

export function searchString ({ ...params }) {
  return queryString.stringify(params)
}

export function hardRedirect (url) {
  if (window) {
    window.location = url
  }
}

export function isExternal (link) {
  return /^https?:\/\//.test(link)
}

export function createObjectURL (...args) {
  return window ? window.URL.createObjectURL(...args) : undefined
}

export function revokeObjectURL (...args) {
  return window ? window.URL.revokeObjectURL(...args) : undefined
}
