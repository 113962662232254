import React, { useState } from 'react'
import PropTypes from 'prop-types'

import CourseCard from '../../UIComponents/CourseCard'
import Tabs from '../../UIComponents/Tabs'
import orderBy from 'lodash.orderby'

import './MyCoursesSummary.scss'
import Carousel from '../../UIComponents/Carousel'

const onDemandsEventTypes = ['Online Learning']

function orderCoursesByDate (courses, sortOrder) {
  return orderBy(courses, ['session.startDate'], [sortOrder])
}

function MyCoursesSummaryProgrammes ({ programmes }) {
  if (!programmes.length) {
    return null
  }

  const renderedProgrammes = programmes.map(({ name }, index) =>
    <li className='my-courses-summary-programme' key={index}>You’re enrolled on the <span className='my-courses-summary-programme-name'>{name}</span></li>
  )

  return <ul className='my-courses-summary-programmes'>{renderedProgrammes}</ul>
}

MyCoursesSummaryProgrammes.propTypes = {
  programmes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired
  })).isRequired
}

function isNotOnDemandCourse (eventType) {
  return !onDemandsEventTypes.includes(eventType)
}
function MyCoursesSummary ({ title, fallback, tabs, courses, programmes }) {
  const now = Date.now()
  // eslint-disable-next-line no-unused-vars
  const [activeTab, setActiveTab] = useState(null)
  const [sourceMap] = useState(
    courses.reduce((map, course) => {
      const { session: { startDate, endDate, eventType } } = course

      const start = new Date(startDate).valueOf()
      const end = new Date(endDate).valueOf()
      if (start < now && end > now && isNotOnDemandCourse(eventType)) {
        map.set('current', [...map.get('current'), course])
      } else if (start > now && isNotOnDemandCourse(eventType)) {
        map.set('future', [...map.get('future'), course])
      } else if (end < now && isNotOnDemandCourse(eventType)) {
        map.set('past', [...map.get('past'), course])
      }
      if (onDemandsEventTypes.includes(eventType)) {
        map.set('onDemand', [...map.get('onDemand'), course])
      }
      return map
    }, new Map([
      ['future', []],
      ['current', []],
      ['past', []],
      ['onDemand', []]
    ]))
  )

  const readyTabs = tabs.map(({ hide, name, source }, index) => {
    let tabCourses = sourceMap.get(source)
    if (!tabCourses.length && hide) {
      return null
    }

    if (source === 'future') {
      tabCourses = orderCoursesByDate(tabCourses, 'asc')
    } else if (source === 'past') {
      tabCourses = orderCoursesByDate(tabCourses, 'desc')
    }

    const children = tabCourses.length
      ? tabCourses.map((course, courseIndex) =>
        <CourseCard variant='large' key={courseIndex} {...course} />
      )
      : <p className='my-courses-summary-fallback'>{fallback}</p>

    return {
      name,
      id: source,
      children: (
        <div className='my-courses-summary-source-courses'>
          {children.length > 1 ? <Carousel keyName={source} items={children} /> : children}
        </div>
      )
    }
  }).filter(tab => tab)

  if (!readyTabs.length) {
    return (
      <section className='my-courses-summary'>
        <h2 className='my-courses-summary-title'>{title}</h2>
        <p className='my-courses-summary-fallback'>{fallback}</p>
      </section>
    )
  }

  const defaultTabId = readyTabs[0].id

  return (
    <section className='my-courses-summary'>
      <h2 className='my-courses-summary-title'>{title}</h2>
      <MyCoursesSummaryProgrammes programmes={programmes} />
      <Tabs tabs={readyTabs} defaultTabId={defaultTabId} change={(e) => setActiveTab(e)}/>
    </section>
  )
}

MyCoursesSummary.propTypes = {
  business: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  fallback: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    hide: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    source: PropTypes.oneOf(['current', 'future', 'past', 'onDemand']).isRequired
  })).isRequired,
  courses: PropTypes.arrayOf(PropTypes.shape({
    session: PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired
    }).isRequired
  })),
  programmes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired
  })).isRequired
}

export default MyCoursesSummary
