import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import Loader from '../../UIComponents/Loader'

import { actions, selectors, store } from '../../store'
import { hardRedirect } from '../../utils/location'

function SSOCallback ({ backend, business, failure, code, state }) {
  const context = useContext(store)
  const [ssoTokenExchanging, setSSOTokenExchanging] = useState(false)
  const { ok, error, redirect } = selectors.ssoTokenExchange(context)
  const { dispatch } = context

  useEffect(() => {
    if (redirect) {
      hardRedirect(redirect)
    }
  }, [redirect])

  useEffect(() => {
    async function exchangeTokens () {
      setSSOTokenExchanging(true)
      await actions.ssoTokenExchange({ dispatch, business, backend, body: { code, failure } })
      setSSOTokenExchanging(false)
    }

    if (!redirect) {
      exchangeTokens()
    }
  }, [dispatch, backend, business, failure, code, state, redirect])

  return (
    <section className='sso-callback'>
      {ok && <Redirect to={Buffer.from(decodeURIComponent(state), 'base64').toString()} />}
      {error && <Redirect to={failure} />}
      <Loader loading={ssoTokenExchanging} size='modal' />
    </section>
  )
}

SSOCallback.propTypes = {
  backend: PropTypes.string.isRequired,
  business: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  state: PropTypes.string
}

export default SSOCallback
