import React from 'react'
import PropTypes from 'prop-types'

import { Containers } from '../../Containers'

import './StandardDummy.scss'

function StandardDummy ({ model }) {
  return (
    <section className='widget-section' id='widgets'>
      <article className='main'>
        <Containers {...model} />
      </article>
    </section>
  )
}

StandardDummy.propTypes = {
  model: PropTypes.object.isRequired
}

export default StandardDummy
