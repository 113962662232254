import React from 'react'
import PropTypes from 'prop-types'

import './RecordOfLearningTotalLearningHours.scss'

function RecordOfLearningTotalLearningHours ({ title, totalCpdHours }) {
  return (
    <section className='total-learning-hours-container'>
      <div className='total-learning-hours-title'>{title}</div>
      <div className='total-learning-hours'>{totalCpdHours}</div>
      <hr></hr>
    </section>
  )
}

RecordOfLearningTotalLearningHours.propTypes = {
  title: PropTypes.string.isRequired,
  totalCpdHours: PropTypes.number.isRequired
}

export default RecordOfLearningTotalLearningHours
