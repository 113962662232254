import React from 'react'
import PropTypes from 'prop-types'

import MarkdownStandard from '../../UIComponents/MarkdownStandard'

import './CourseBodyPreSaleInformation.scss'

function CourseBodyPreSaleInformation ({ course: { preSaleInformation } }) {
  return (
    <div className='course-body-pre-sale-information'>
      <MarkdownStandard className='course-body-pre-sale-information-information'>{preSaleInformation}</MarkdownStandard>
    </div>
  )
}

CourseBodyPreSaleInformation.propTypes = {
  course: PropTypes.shape({
    preSaleInformation: PropTypes.string.isRequired
  })
}

export default CourseBodyPreSaleInformation
