import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { selectors, store } from '../../store'
import ContentCard from '../../UIComponents/ContentCard'
import RecommendationPanel from '../../UIComponents/RecommendationPanel'
import UIAlert from '../../UIComponents/UIAlert'
import { hasINVSector, hasEWSector } from '../../utils/user'

import './RecommendedNewsSummary.scss'

function RecommendedNewsSummary ({ title, news, investigationTitle }) {
  const { state } = useContext(store)
  const fallback = news.length ? null : (
    <div className='recommended-news-fallback'>
      <UIAlert level='info' message='No news items were found.' icon='search' iconPrefix='fas' />
    </div>
  )
  const currentCustomer = selectors.currentCustomer({ state })
  const isInvestigationSector = hasINVSector(currentCustomer?.sectors)
  const isExpertwitnessSector = hasEWSector(currentCustomer?.sectors)
  title = (isInvestigationSector && isExpertwitnessSector === false) ? investigationTitle : title
  console.log(`RecommendedNewsSummery widtget user has investigation sector : ${isInvestigationSector} AND expertWitness: ${isExpertwitnessSector} & title : ${title}`)
  const newsItems = news.slice(0, 3) // Temporary for now
    .map(({ path: link, title: newsTitle, publishedDate, lastEditedDate, image, time, contentType }, index) => (
      <div className='ui-component-card-full-image'>
        <ContentCard
          variant='normal'
          type={contentType}
          image={image}
          link={link}
          publicationDate={publishedDate}
          editedDate={lastEditedDate}
          time={time}
          title={newsTitle}
          key={index}
        />
      </div>
    ))

  return (
    <RecommendationPanel title={title}>
      {newsItems}
      {fallback}
    </RecommendationPanel>
  )
}

RecommendedNewsSummary.propTypes = {
  business: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  news: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string.isRequired,
      publishedDate: PropTypes.string.isRequired,
      lastEditedDate: PropTypes.string,
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      contentType: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      sectors: PropTypes.arrayOf(PropTypes.string.isRequired),
      tags: PropTypes.arrayOf(PropTypes.string.isRequired),
      time: PropTypes.number.isRequired
    })
  ).isRequired
}

export default RecommendedNewsSummary
