import React from 'react'
import PropTypes from 'prop-types'

import './FooterContainer.scss'

function FooterContainer ({ children, ...props }) {
  return <section className='footer-container' {...props}>{children}</section>
}

FooterContainer.propTypes = {
  children: PropTypes.node
}

export default FooterContainer
