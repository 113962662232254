import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { selectors, store } from '../../store'
import CourseCard from '../../UIComponents/CourseCard'
import RecommendationPanel from '../../UIComponents/RecommendationPanel'
import { hasEWSector, hasINVSector } from '../../utils/user'

import './RecommendedCoursesSummary.scss'

function RecommendedCoursesSummary ({ title, courses = [], investigationTitle }) {
  const { state } = useContext(store)
  if (courses.length === 0) {
    return null
  }
  const currentCustomer = selectors.currentCustomer({ state })
  const isInvestigationSector = hasINVSector(currentCustomer?.sectors)
  const isExpertwitnessSector = hasEWSector(currentCustomer?.sectors)
  title = (isInvestigationSector && isExpertwitnessSector === false) ? investigationTitle : title
  console.log(`RecommendedCoursesSummery widtget user has investigation sector : ${isInvestigationSector}  AND expertWitness: ${isExpertwitnessSector} & title : ${title}`)
  const course = courses[0]
  return (
    <RecommendationPanel endToEnd='recommended-courses' title={title} customClass="dimWhite">
      <CourseCard variant='large' {...course} />
      <CourseCard variant='testimonial' {...course} />
    </RecommendationPanel>
  )
}

RecommendedCoursesSummary.propTypes = {
  business: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  courses: PropTypes.arrayOf(PropTypes.shape({
    session: PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired
    })
  })).isRequired
}

export default RecommendedCoursesSummary
