import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { selectors, store } from '../../store'
import FileList from '../../UIComponents/FileList'
import MarkdownStandard from '../../UIComponents/MarkdownStandard'
import Tabs from '../../UIComponents/Tabs'
import Table from '../../UIComponents/Table'

import * as dateUtils from '../../utils/date'
import { getCourseLink } from '../../utils/courseUtil'
import { replaceInstances } from '../../utils/string'
import { hasEWSector, hasINVSector } from '../../utils/user'

import './MyProgrammesSummary.scss'
import ProgrammeDigibadgeDownload from '../ProgrammeDigibadgeDownload'

function MyProgrammesSummaryFallback ({ title, fallback, fallbackInvestigationPortable }) {
  const { state } = useContext(store)
  const currentCustomer = selectors.currentCustomer({ state })
  const isInvestigationSector = hasINVSector(currentCustomer?.sectors)
  const isExpertWintesSector = hasEWSector(currentCustomer?.sectors)
  console.log(`Your profile sectors ${isInvestigationSector} & isExpertWintesSector : ${isExpertWintesSector}`)
  // Investigation fallback message will be display only if user has investigation sector.If both secrots there default fallback will be take place
  fallback = (isInvestigationSector && isExpertWintesSector === false) ? fallbackInvestigationPortable : fallback
  return (
    <section className='my-programmes-summary'>
      <h2 className='my-programmes-summary-title'>{title}</h2>
      <MarkdownStandard className='my-programmes-summary-fallback'>{fallback}</MarkdownStandard>
    </section>
  )
}

function MyProgrammesSummaryIntroduction ({ programmeDetailIntroduction, programme }) {
  if (!programmeDetailIntroduction) {
    return null
  }

  const message = replaceInstances({ data: { programme }, text: programmeDetailIntroduction })
  return <MarkdownStandard className='my-programmes-summary-introduction'>{message}</MarkdownStandard>
}

// TODO:Deprecated method, will go away soon.
function MyProgrammeSummaryGuidanceNotes ({ programme }) {
  const { guidanceNotes } = programme
  if (!guidanceNotes) {
    return null
  }

  const { url: href, originalFilename: name, mimeType: type } = guidanceNotes
  return <FileList files={[{ href, name, type }]} />
}

// TODO:Rename this function to MyProgrammeSummaryGuidanceNotes, once above method removed
function MyProgrammeSummaryGuidanceNotes1 ({ programme }) {
  const { guidanceNotes1 } = programme
  if (!guidanceNotes1) {
    return null
  }

  const files = guidanceNotes1.map(({ url: href, originalFilename: name, mimeType: type }) =>
    ({ href, name, type })
  )
  return <FileList files={files} />
}

function MyProgrammeSummaryAdditionalInformation ({ additionalInformation, programme }) {
  if (!additionalInformation) {
    return null
  }

  return (
    <>
      <MarkdownStandard className='my-programmes-summary-additional'>{additionalInformation}</MarkdownStandard>
      <MyProgrammeSummaryGuidanceNotes programme={programme} />
      <MyProgrammeSummaryGuidanceNotes1 programme={programme} />
    </>
  )
}

function CoursePreCourseworkSummary ({ course }) {
  const { preCourseworkInformationSummary } = course
  if (!preCourseworkInformationSummary) {
    return null
  }

  return <MarkdownStandard className='my-programmes-summary-additional'>{preCourseworkInformationSummary}</MarkdownStandard>
}

function CourseTitleLink ({ course }) {
  const { title, path, session: { salesForceCourseEventID, lmsCourseID, eventType } } = course
  const to = getCourseLink({ path, salesForceCourseEventID, type: eventType, lmsCourseID })
  return <Link className='my-programmes-course-link' to={to}>{title}</Link>
}

function getStartDate ({ session }) {
  const { startDate } = session
  return dateUtils.format({ date: startDate, preset: 'short' })
}

function getData ({ course, name, programme }) {
  switch (name) {
    case 'course.assessmentInformation':
      return <MarkdownStandard className='my-programmes-summary-additional'>{course.assessmentInformation}</MarkdownStandard>
    case 'course.name':
      return <CourseTitleLink course={course} />
    case 'course.preCourseworkInformationSummary':
      return <CoursePreCourseworkSummary course={course} />
    case 'courseEvent.startDate':
      return getStartDate({ session: course.session })
    default:
      return ''
  }
}

function hasDigibageInfo (program) {
  return program?.completionDate && program?.digibadges?.length
}

function MyProgrammeSummary ({ additionalInformation, programmeDetailIntroduction, programme, headings, digibadgetButtonText, digibadgeTitle }) {
  const data = programme.units.map((course) => {
    return headings.reduce((data, { name }) => {
      data[name] = getData({ course, name, programme })
      return data
    }, {})
  })
  const hasDigibadge = hasDigibageInfo(programme)
  console.log('Has digibageInfo to show', hasDigibadge)
  return (
    <div className='my-programmes-summary-programme'>
      <MyProgrammesSummaryIntroduction programmeDetailIntroduction={programmeDetailIntroduction} programme={programme} />
      {hasDigibadge && <ProgrammeDigibadgeDownload title={digibadgeTitle} programmeDigibadgeDownloadButtonText={digibadgetButtonText} programme={programme} digibadges={programme.digibadges} /> }
      <Table className='my-programmes-summary-table' headings={headings} data={data} />
      <MyProgrammeSummaryAdditionalInformation additionalInformation={additionalInformation} programme={programme} />
    </div>
  )
}

function renderProgrammeCUBSDetails (cubsDetails) {
  return <MarkdownStandard className='my-programmes-summary-additional'>{cubsDetails}</MarkdownStandard>
}

function MyProgrammesSummary ({ title, additionalInformation, fallback, programmeDetailIntroduction, programmeDetailTable, programmes, fallbackInvestigationPortable, tabs, cubsDetails, programmeDigibadgeDownloadButtonText, cubsTitle }) {
  // if (!programmes.length) {
  //   return <MyProgrammesSummaryFallback title={title} fallback={fallback} fallbackInvestigationPortable={fallbackInvestigationPortable} />
  // }
  // console.log("MyProgrammeSummary")
  const headings = programmeDetailTable.map(({ data: name, heading: label }) => ({ name, label }))
  const renderedProgrammes = programmes.map((programme, index) =>
    <MyProgrammeSummary
      key={index}
      additionalInformation={additionalInformation}
      programmeDetailIntroduction={programmeDetailIntroduction}
      programme={programme}
      headings={headings}
      digibadgetButtonText={programmeDigibadgeDownloadButtonText}
      digibadgeTitle={cubsTitle}
    />
  )

  const [sourceMap] = useState(
    new Map([
      ['allProgrammes', []],
      ['cubsInformation', []]
    ]))
  console.log('renderProgrammeCUBSDetails(cubsDetails)', renderProgrammeCUBSDetails(cubsDetails))
  // eslint-disable-next-line no-unused-vars
  const [activeTab, setActiveTab] = useState(null)

  const readyTabs = tabs.map(({ hide, name, source }, index) => {
    let tabData = sourceMap.get(source)
    if (!tabData.length && hide) {
      return null
    }
    if (source === 'allProgrammes') {
      tabData = renderedProgrammes
    }
    if (source === 'cubsInformation') {
      tabData = renderProgrammeCUBSDetails(cubsDetails)
    }
    return { name, id: source, children: tabData }
  }).filter(tab => tab)

  console.log(`My program page ready tabs: ${readyTabs.length}`)
  if (!readyTabs.length) {
    return <MyProgrammesSummaryFallback title={title} fallback={fallback} fallbackInvestigationPortable={fallbackInvestigationPortable} />
  }
  // eslint-disable-next-line no-unused-vars
  const defaultTabId = readyTabs[0].id

  return (
    <section className='my-programmes-summary'>
      <h2 className='my-programmes-summary-title'>{title}</h2>
      {/* {renderedProgrammes} */}
      <Tabs tabs={readyTabs} defaultTabId={defaultTabId} change={(e) => setActiveTab(e)}/>
    </section>
  )
}

MyProgrammesSummary.propTypes = {
  business: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  additionalInformation: PropTypes.string,
  fallback: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    hide: PropTypes.bool,
    name: PropTypes.string.isRequired,
    source: PropTypes.oneOf(['allProgrammes', 'cubsInformation']).isRequired
  })).isRequired,
  programmeDetailIntroduction: PropTypes.string,
  programmeDetailTable: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired
  })).isRequired,
  programmes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    salesForceProgrammeID: PropTypes.string.isRequired,
    guidanceNotes: PropTypes.shape({
      mimeType: PropTypes.string.isRequired,
      originalFilename: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }),
    guidanceNotes1: PropTypes.arrayOf(PropTypes.shape({
      mimeType: PropTypes.string.isRequired,
      originalFilename: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })),
    units: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      session: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired
      }).isRequired
    })).isRequired
  })).isRequired,
  cubsDetails: PropTypes.string
}

export default MyProgrammesSummary
