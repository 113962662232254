import React, { useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import ContentCard from '../../UIComponents/ContentCard'
import FilterButton from '../../UIComponents/FilterButton'
import Loader from '../../UIComponents/Loader'
import RecommendationPanel from '../../UIComponents/RecommendationPanel'
import Search from '../../UIComponents/Search'
import UIAlert from '../../UIComponents/UIAlert'
import Pagination from '../../UIComponents/Pagination'

import { actions, selectors, store } from '../../store'
import { useDebounce } from '../../utils/hooks'

import './NewsList.scss'

export const delay = 500

function NewsList ({ allTags = [], news, recommended, search = '', tags = [], title = '', searchAllowed = true }) {
  const context = useContext(store)
  const { dispatch } = context
  const { business } = selectors.business(context)
  const { pathname } = useLocation()
  const [loading, setLoading] = useState(false)
  const [showPaginator, setShowPaginator] = useState(true)

  const [recommendedValue, setRecommendedValue] = useState(recommended)
  const [searchValue, setSearchValue] = useState(search)
  const [filteredNewsItems, setFilteredNewsItems] = useState([])

  const [pageNumber, setPageNumber] = useState(0)
  const [newsPerPage] = useState(12)
  const pagesVisited = pageNumber * newsPerPage
  const [pageCount, setPageCount] = useState(Math.ceil(news.length / newsPerPage))

  const newsItems = news.slice(pagesVisited, pagesVisited + newsPerPage).map(({ path: link, title: newsTitle, image, time, publishedDate, lastEditedDate, contentType }, index) => (
    <div className='ui-component-card-full-image'>
      <ContentCard
        type={contentType}
        variant='normal'
        image={image}
        link={link}
        publicationDate={publishedDate}
        editedDate={lastEditedDate}
        time={time}
        title={newsTitle}
        key={index}
      />
    </div>
  ))
  const debouncedSearchValue = useDebounce(searchValue, delay)

  useEffect(() => {
    async function action () {
      setLoading(true)
      await actions.newsListUpdate({
        business,
        dispatch,
        pathname,
        recommended: recommendedValue,
        search: '',
        tags: []
      })
      setLoading(false)
    }

    if (debouncedSearchValue && recommendedValue) {
      setRecommendedValue(false)
    }

    // Only run this when there's a change
    if (recommendedValue !== recommended) {
      action()
    }

    let filteredNewsItems = []

    if (debouncedSearchValue) {
      filteredNewsItems = news.filter((item) => {
        return (item.title.toLowerCase().includes(debouncedSearchValue) || item.body.toLowerCase().includes(debouncedSearchValue.toLowerCase()))
      }).map(({ path: link, title: newsTitle, image, time, publishedDate, lastEditedDate, contentType }, index) => (
        <div className='ui-component-card-full-image'>
          <ContentCard
            type={contentType}
            variant='normal'
            image={image}
            link={link}
            publicationDate={publishedDate}
            editedDate={lastEditedDate}
            time={time}
            title={newsTitle}
            key={index}
          />
        </div>
      ))

      setPageCount(Math.ceil(filteredNewsItems.length / newsPerPage))
      if (filteredNewsItems.length <= newsPerPage) {
        setPageNumber(0)
        filteredNewsItems = filteredNewsItems.slice(0, newsPerPage)
        setShowPaginator(false)
      } else {
        filteredNewsItems = filteredNewsItems.slice(pagesVisited, pagesVisited + newsPerPage)
        setShowPaginator(true)
      }
      setFilteredNewsItems(filteredNewsItems)
    } else {
      setPageCount(Math.ceil(news.length / newsPerPage))
      setShowPaginator(true)
    }
  }, [recommended, recommendedValue, search, debouncedSearchValue, news, business, dispatch, pathname, pageNumber, newsPerPage, pagesVisited])

  const header = (
    <div className='news-list-filters'>
      <div className='news-list-filters-buttons'>
        {!debouncedSearchValue && <FilterButton className='news-list-filters-button' selected={recommendedValue} change={() => setRecommendedValue(true)} value='Show recommended' />}
        {!debouncedSearchValue && <FilterButton className='news-list-filters-button' selected={!recommendedValue} change={() => setRecommendedValue(false)} value='Show all' />}
      </div>
      { searchAllowed && <Search change={(value) => setSearchValue(value)} submit={(value) => setSearchValue(value)} initialValue={searchValue} className='news-list-filters-search' />}
    </div>
  )

  const handlePageClick = (e) => {
    const selectedPage = e.selected
    setPageNumber(selectedPage)
  }
  let content = newsItems
  if (debouncedSearchValue && filteredNewsItems.length > 0 && recommendedValue === false) {
    content = filteredNewsItems
  } else if (debouncedSearchValue && filteredNewsItems.length === 0 && recommendedValue === false) {
    content = <div className='news-list-fallback'>
      <UIAlert level='info' message='No news items were found.' additional='Try widening your search' icon='search' iconPrefix='fas' />
    </div>
  }

  return (
    <>
      <RecommendationPanel title={title} header={header} variant='clear'>
        {content}
        <Loader loading={loading} className='news-list-loader' />
      </RecommendationPanel>
      {showPaginator && <Pagination previousLabel={'Prev'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        forcePage={pageNumber} />}
    </>
  )
}

NewsList.propTypes = {
  allTags: PropTypes.arrayOf(PropTypes.string.isRequired),
  articleCount: PropTypes.number.isRequired,
  articleLimit: PropTypes.number.isRequired,
  business: PropTypes.string.isRequired,
  news: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string.isRequired,
      publishedDate: PropTypes.string.isRequired,
      lastEditedDate: PropTypes.string,
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      contentType: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      sectors: PropTypes.arrayOf(PropTypes.string.isRequired),
      tags: PropTypes.arrayOf(PropTypes.string.isRequired),
      time: PropTypes.number.isRequired
    })
  ).isRequired,
  search: PropTypes.string.isRequired,
  recommended: PropTypes.bool.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired),
  title: PropTypes.string
}

export default NewsList
