import React from 'react'
import PropTypes from 'prop-types'
import lowercase from 'lodash.lowercase'
import upperfirst from 'lodash.upperfirst'

import Head from '../../UIComponents/Head'
import { IconNew } from '../../UIComponents/Icon'

import * as dateUtils from '../../utils/date'

import './CourseHeader.scss'

function certificateDetails ({ programme }) {
  if (!programme) {
    return
  }

  const { name } = programme
  return `Part of the ${name}`
}

function eventStartDate ({ session }) {
  if (!session) {
    return
  }

  const { startDate: date } = session
  const startDate = dateUtils.format({ date, preset: 'dateTime' })
  return `${startDate}`
}

function eventFormat ({ session }) {
  if (!session) {
    return
  }

  const { format: rawFormat } = session
  const format = upperfirst(lowercase(rawFormat))
  return format
}

const courseSummaryPoints = new Map([
  ['certificateDetails', certificateDetails],
  ['eventStartDate', eventStartDate],
  ['eventFormat', eventFormat]
])

function getStyle (image) {
  const backgroundImage = image ? `url(${image})` : 'none'
  return { backgroundImage }
}

function CourseHeader ({ course, summaryPoints }) {
  const points = summaryPoints.map(({ icon, type }, index) => {
    const text = courseSummaryPoints.get(type)(course)

    if (!text) {
      return null
    }

    return (
      <li className='course-header-summary-point' key={index}>
        <div className='course-header-summary-point-icon-container'>
          <IconNew className='course-header-summary-point-icon' icon={icon} title={type} />
        </div>
        <span className='course-header-summary-point-text'>{text}</span>
      </li>
    )
  })

  const { image, title } = course

  const imageStyle = getStyle(image)
  return (
    <header className='course-header' style={imageStyle}>
      <Head title={title} image={image} />
      <div className='course-header-content'>
        <div className='course-header-content-titles'>
          <h1 className='course-header-content-title'>{title}</h1>
        </div>
        <ul className='course-header-summary-points'>{points}</ul>
      </div>
    </header>
  )
}

CourseHeader.propTypes = {
  summaryPoints: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    type: PropTypes.oneOf([...courseSummaryPoints.keys()]).isRequired
  })),
  course: PropTypes.shape({
    image: PropTypes.string.isRequired
  })
}

export default CourseHeader
