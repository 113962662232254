import React from 'react'
import PropTypes from 'prop-types'

import UIAlert from '../UIAlert'

import './ui-error.scss'

function UIError ({ message, level, additional, dismissHandler = () => {}, showCloseButton = false }) {
  const icon = 'exclamation'
  const iconPrefix = 'fas'
  return (
    <UIAlert level={level} message={message} additional={additional} dismissHandler={dismissHandler} icon={icon} iconPrefix={iconPrefix} showCloseButton={showCloseButton} />
  )
}

UIError.propTypes = {
  message: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  additional: PropTypes.string,
  dismissHandler: PropTypes.func,
  showCloseButton: PropTypes.bool
}

export default UIError
