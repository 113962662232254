import React from 'react'
import PropTypes from 'prop-types'

import './Table.scss'

function Table ({ data, headings, title, layout = 'fixed' }) {
  const renderedHeadings = headings.map(({ label }, index) =>
    <th key={index} className='ui-component-table-heading'>{label}</th>
  )

  const order = headings.map(({ name }) => name)

  const renderedRows = data.map((row, rowIndex) => {
    const cells = order.map((name, cellIndex) => {
      const value = row[name] || ''
      return <td key={cellIndex} className='ui-component-table-cell'>{value}</td>
    })
    return <tr key={rowIndex} className='ui-component-table-row'>{cells}</tr>
  })

  const tableClass = `ui-component-table-table-${layout}`

  return (
    <div className='ui-component-table'>
      {title && <h3 className='ui-component-table-title'>{title}</h3>}
      <div className='ui-component-table-container'>
        <table className={tableClass}>
          <thead className='ui-component-table-head'>
            <tr className='ui-component-table-head-row'>{renderedHeadings}</tr>
          </thead>
          <tbody className='ui-component-table-body'>
            {renderedRows}
          </tbody>
        </table>
      </div>
    </div>
  )
}

Table.propTypes = {
  headings: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  layout: PropTypes.oneOf(['auto', 'fixed'])
}

export default Table
