import { getPageData, widgetBackendPostForm, widgetBackendPostJSON } from '../utils/api'

// TODO: consolidate dispatch/context

export async function documentDelete ({ dispatch, backend, business, body }) {
  let response
  try {
    response = await widgetBackendPostJSON({ backend, business, body })
  } catch (error) {
    console.error('actions.documentDelete', error)
    return
  }

  const { courseId, sessionId } = body
  const { documents } = response
  dispatch({ type: 'course event documents', courseId, sessionId, documents })
}
export async function documentUpload ({ dispatch, backend, business, body, file, localId }) {
  dispatch({ type: 'document upload initiate', localId, file })

  let response
  try {
    response = await widgetBackendPostForm({ backend, business, body, file })
  } catch (error) {
    console.error('actions.documentUpload', error)
    dispatch({ type: 'document upload failure', error, localId })
    return
  }

  dispatch({ type: 'document upload success', localId })
  const { courseId, sessionId } = body
  const { documents } = response
  dispatch({ type: 'course event documents', courseId, sessionId, documents })
}

export async function documentUploadCleanup ({ dispatch, localId }) {
  dispatch({ type: 'document upload cleanup', localId })
}

export async function fetchPage ({ dispatch, pathname, search, business }) {
  dispatch({ type: 'route pending' })
  const data = await getPageData({ pathname, search, business })
  dispatch({ type: 'route update', data })
}

export function modal ({ dispatch, active }) {
  dispatch({ type: 'modal active', active })
}

export async function newsListUpdate ({ dispatch, business, pathname, tags, search, recommended }) {
  const params = { recommended }
  if (tags.length) {
    params.tags = tags
  }
  if (search) {
    params.search = search
  }

  const data = await getPageData({ pathname, params, business })
  dispatch({ type: 'route update', data })
}

export async function resourcesListUpdate ({ dispatch, business, pathname, search, recommended }) {
  const params = { recommended }

  if (search) {
    params.search = search
  }

  const data = await getPageData({ pathname, params, business })
  dispatch({ type: 'route update', data })
}

export function setCourseEventDocuments ({ dispatch, courseId, sessionId, documents }) {
  dispatch({ type: 'course event documents', courseId, sessionId, documents })
}

export async function ssoTokenExchange ({ dispatch, business, backend, body }) {
  let response
  try {
    response = await widgetBackendPostJSON({ business, backend, body })
  } catch (error) {
    dispatch({ type: 'sso token exchange failure', error })
    return
  }

  const { redirect } = response

  if (redirect) {
    dispatch({ type: 'sso token exchange redirect', redirect })
    return
  }

  dispatch({ type: 'sso token exchange success' })
}

export async function ssoSignOut ({ dispatch, business, backend }) {
  try {
    await widgetBackendPostJSON({ business, backend, body: {} })
  } catch (error) {
    dispatch({ type: 'sso sign out failure', error })
    return
  }
  dispatch({ type: 'sso sign out success' })
}

export function profileMenuHide ({ dispatch }) {
  dispatch({ type: 'profile menu hide' })
}

export function profileMenuShow ({ dispatch }) {
  dispatch({ type: 'profile menu show' })
}

export function sideBarMenuHide ({ dispatch }) {
  dispatch({ type: 'sidebar menu hide' })
}

export function sideBarMenuShow ({ dispatch }) {
  dispatch({ type: 'sidebar menu show' })
}
