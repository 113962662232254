import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import Avatar from '../../UIComponents/Avatar'
import Icon from '../../UIComponents/Icon'

import { useEscapeKey } from '../../utils/hooks'

import { actions, store, selectors } from '../../store'

import './HeaderNavigation.scss'

function HeaderNavigationProfileMenu ({ items }) {
  const { dispatch, state } = useContext(store)
  const visible = selectors.profileMenuVisible({ state })

  function escape (event) {
    if (!visible) {
      return
    }
    if (event.key !== 'Esc' && event.key !== 'Escape') {
      return
    }

    actions.profileMenuHide({ dispatch })
  }

  useEscapeKey(escape)

  const className = visible ? 'profile-menu-visible' : 'profile-menu-invisible'
  const overlayClassName = visible ? 'profile-menu-overlay-visible' : 'profile-menu-overlay-invisible'

  const renderedItems = items.map(({ text, uid }, index) =>
    <li className='profile-menu-navigation-list-item' key={index}>
      <Link to={uid} className='profile-menu-navigation-list-item-link'>{text}</Link>
    </li>
  )

  function profileMenuHide (event) {
    event.preventDefault()
    actions.profileMenuHide({ dispatch })
  }

  return (
    <>
      <div className={className}>
        <ul className='profile-menu-navigation-list'>
          {renderedItems}
        </ul>
      </div>
      <div className={overlayClassName} onClick={(event) => profileMenuHide(event)} aria-hidden='true' />
    </>
  )
}

function HeaderNavigation ({ customer, favourites, help, notifications, profileMenuItems }) {
  const { dispatch } = useContext(store)
  useEffect(() => actions.profileMenuHide({ dispatch }), [dispatch])

  const { firstName, lastName } = customer
  const customerName = `${firstName} ${lastName}`

  function profileMenuShow (event) {
    event.preventDefault()
    actions.profileMenuShow({ dispatch })
  }

  return (
    <section className='header-navigation'>
      <ul className='header-navigation-list'>
        <li style={{ display: 'none' }} className='header-navigation-list-item header-navigation-list-item-notifications'>
          <Icon className='header-navigation-list-item-icon-small' icon={notifications.name} prefix={notifications.prefix} title='Notifications' />
        </li>
        <li className='header-navigation-list-item'>
          <Link to={help.uid}>
            <Icon className='header-navigation-list-item-icon' icon={help.name} prefix={help.prefix} title='Help' />
          </Link>
        </li>
        <li className='header-navigation-list-item-avatar'>
          <button type='button' onClick={(event) => profileMenuShow(event)} className='header-navigation-list-item-avatar-button'>
            <Avatar className='header-navigation-list-item-avatar-avatar' name={customerName} size='small' />
          </button>
          <HeaderNavigationProfileMenu items={profileMenuItems} />
        </li>
      </ul>
    </section>
  )
}

HeaderNavigation.propTypes = {
  business: PropTypes.string.isRequired,
  help: PropTypes.shape({
    name: PropTypes.string.isRequired,
    prefix: PropTypes.oneOf(['fas', 'far']).isRequired
  }).isRequired,
  notifications: PropTypes.shape({
    name: PropTypes.string.isRequired,
    prefix: PropTypes.oneOf(['fas', 'far']).isRequired
  }).isRequired,
  profileMenuItems: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired
  })).isRequired,
  customer: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    jobTitle: PropTypes.string,
    companyName: PropTypes.string
  })
}

export default HeaderNavigation
