import React, { useEffect, useRef } from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import './carousel.scss'
import PropTypes from 'prop-types'

SwiperCore.use([Navigation])

function Carousel ({ items, slidesPerView = 1.40 }) {
  const slides = items.length
    ? items.map((itemContent, itemIndex) =>
      <SwiperSlide key={itemIndex}>{itemContent}</SwiperSlide>
    )
    : <p className='empty-contents-fallback'>You have no courses to show.</p>

  const carousel = useRef(null)
  const { current } = carousel
  useEffect(() => {
    if (!current || !current.swiper) {
      console.warn(`No carousel detected ${current}`)
      return
    }

    if (!current.swiper.update) {
      console.error(`Unable to use update function ${current.swiper}`)
      return
    }

    current.swiper.update()
    console.log('updated carousel')
  }, [current])

  return (
    <Swiper
      ref={carousel}
      navigation
      breakpoints={{
        // when window width is >= 640px
        480: {
          width: 480,
          slidesPerView: 1
        },
        // when window width is >= 640px
        640: {
          width: 640,
          slidesPerView: 1
        },
        // when window width is >= 768px
        992: {
          width: 992,
          slidesPerView: slidesPerView
        }
      }}
      observeParents={true}
      observer={true}
    >
      {slides}
    </Swiper>
  )
}

Carousel.propTypes = {
  items: PropTypes.array.isRequired,
  slidesPerView: PropTypes.number
}

export default Carousel
