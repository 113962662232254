import React, { useContext, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import Icon from '../../UIComponents/Icon'

import { useEscapeKey } from '../../utils/hooks'
import { actions, store, selectors } from '../../store'
import { hasEWSector, hasINVSector, hasINVTags } from '../../utils/user'
import './SidebarNavigation.scss'

function SidebarNavigation ({ items }) {
  const { dispatch, state } = useContext(store)

  const currentCustomer = selectors.currentCustomer({ state })
  const { profile: { tags } } = currentCustomer

  const isInvestigationSector = hasINVSector(currentCustomer?.sectors)
  const isExpertWintesSector = hasEWSector(currentCustomer?.sectors)
  const isINVTag = hasINVTags(tags)
  const hasOnlyInvestigationSector = isInvestigationSector && !isExpertWintesSector

  useEffect(() => actions.sideBarMenuHide({ dispatch }), [dispatch])
  const visible = selectors.sideBarMenuVisible({ state })

  const className = visible ? 'sidebar-navigation-visible' : 'sidebar-navigation'
  const overlayClassName = visible ? 'sidebar-menu-overlay-visible' : 'sidebar-menu-overlay-invisible'

  function escape (event) {
    if (!visible) {
      return
    }
    if (event.key !== 'Esc' && event.key !== 'Escape') {
      return
    }

    actions.sideBarMenuHide({ dispatch })
  }

  useEscapeKey(escape)

  function hideSideNavBar () {
    actions.sideBarMenuHide({ dispatch })
  }

  console.log(`Has Only investigationSector sector ${hasOnlyInvestigationSector} & isINVTag ${isINVTag} :::: totalItem : ${items.length}`)

  let filteredMenus = []
  if (hasOnlyInvestigationSector && !isINVTag) {
    items.forEach((item) => {
      if (item?.text !== 'Resources' && item?.text !== 'News & Insights') {
        filteredMenus.push(item)
      }
    })
  }

  filteredMenus = filteredMenus.length ? filteredMenus : items

  const renderedItems = filteredMenus.map(({ iconName, iconPrefix, text, uid }, index) => {
    return (
      <li className='sidebar-navigation-list-item' key={index}>
        <NavLink className='sidebar-navigation-list-item-link' activeClassName='sidebar-navigation-list-item-link-active' to={uid} onClick={hideSideNavBar}>
          <Icon className='sidebar-navigation-list-item-icon' icon={iconName} prefix={iconPrefix} title={text} />
          <span className='sidebar-navigation-list-item-text'>{text}</span>
        </NavLink>
      </li>
    )
  })

  return (
    <>
      <div className={overlayClassName} onClick={(event) => hideSideNavBar(event)} aria-hidden='true' />
      <section className={className}>
        <ul className='sidebar-navigation-list'>
          {renderedItems}
        </ul>
      </section>
    </>
  )
}

SidebarNavigation.propTypes = {
  business: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    iconName: PropTypes.string.isRequired,
    iconPrefix: PropTypes.oneOf(['fas', 'far']),
    text: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired
  }))
}

export default SidebarNavigation
