import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import Container from '../../Containers'

import Burger from '../../UIComponents/Burger'
import LogoBondSolon from '../../UIComponents/LogoBondSolon'

import { actions, selectors, store } from '../../store'

import './Lovegrass.scss'

function renderLogo ({ business }) {
  switch (business) {
    case 'ica':
      return null
    case 'bondSolon':
    default:
      return <LogoBondSolon className='lovegrass-header-logo-icon' />
  }
}

function LovegrassHeader ({ business, container }) {
  const { dispatch, state } = useContext(store)

  const logo = renderLogo({ business })

  const { active } = selectors.modal({ state })

  const headerContainerClassName = active ? 'lovegrass-header-container-modal' : 'lovegrass-header-container'
  const visible = selectors.sideBarMenuVisible({ state })

  function toggleSideBarMenu () {
    const visible = selectors.sideBarMenuVisible({ state })
    if (visible) {
      actions.sideBarMenuHide({ dispatch })
    } else {
      actions.sideBarMenuShow({ dispatch })
    }
  }

  return <div className='lovegrass-header'>
    <div className='lovegrass-header-content'>
      <Burger className='lovegrass-burger' active={visible} toggle={(event) => toggleSideBarMenu(event)} />
      <Link className='lovegrass-header-logo' to='/home'>{logo}</Link>
      <Container className={headerContainerClassName} {...container} />
    </div>
  </div>
}

function Lovegrass ({ business, model }) {
  const { containers } = model
  const {
    footerContainer,
    headerContainer,
    mainContainer
  } = containers.reduce((acc, container) => ({ ...acc, [container.type]: container }), {})

  return (
    <div className='lovegrass'>
      <div className='lovegrass-header-placeholder'>
        <LovegrassHeader container={headerContainer} />
      </div>
      <div className='lovegrass-main'>
        <Container {...mainContainer} />
      </div>
      <div className='lovegrass-footer'>
        <Container {...footerContainer} />
      </div>
    </div>
  )
}

Lovegrass.propTypes = {
  model: PropTypes.shape({
    containers: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired
      })
    ).isRequired,
    variation: PropTypes.string,
    backgroundImage: PropTypes.string
  }).isRequired
}

export default Lovegrass
