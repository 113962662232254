import React from 'react'
import PropTypes from 'prop-types'

import './Avatar.scss'

function getStyle (image) {
  const backgroundImage = image ? `url(${image})` : 'none'
  return { backgroundImage }
}

const initialMatch = new RegExp(/(?:([^\s])[^\s]*)/gi)

function getInitials (name) {
  const safeName = name.replace(/[-']/g, ' ')
  const matches = []
  let tempMatches
  while ((tempMatches = initialMatch.exec(safeName)) !== null) {
    matches.push(tempMatches.pop())
  }
  return matches.join('')
}

function Avatar ({ image, name, size = 'small', className = '' }) {
  const imageStyle = getStyle(image)
  const initials = getInitials(name)
  const avatarClassName = `ui-component-avatar-${size} ${className}`.trim()
  return (
    <div className={avatarClassName} role='presentation'>
      <span className='ui-component-avatar-initials'>{initials}</span>
      <div className='ui-component-avatar-image' style={imageStyle} />
    </div>
  )
}

Avatar.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
}

export default Avatar
