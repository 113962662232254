import React, { useContext, useEffect, useState } from 'react'

import { selectors, store } from '../../store'
import { useScript } from '../../utils/hooks'

const EWLA3Codes = ['EX1', 'EX2', 'EX3', 'EX4', 'EX6', 'EX8']
const INVLA3Codes = ['EP1', 'EP2', 'EP7', 'IN1', 'IN2', 'IN3', 'IN4', 'IN5', 'IN6', 'IN7', 'IN8', 'IN9', 'TC1', 'TC10', 'HS1']

// ExpertWitness sectors
const hasEWSector = (sectors) => {
  return !!sectors.filter(sector => EWLA3Codes.includes(sector)).length
}
// Investigation sectors
const hasINVSector = (sectors) => {
  return !!sectors.filter(sector => INVLA3Codes.includes(sector)).length
}

function Survicate ({ workspace }) {
  const { state } = useContext(store)

  const surveyCompletions = selectors.currentCustomerSurveyCompletions({ state })
  const salesForceContactID = selectors.currentCustomersalesForceContactID({ state })
  const currentCustomer = selectors.currentCustomer({ state })

  const [surveyCompleted, setSurveyCompleted] = useState(false)
  const [survicateReady, setSurvicateReady] = useState(false)

  const src = workspace ? `https://survey.survicate.com/workspaces/${workspace}/web_surveys.js` : ''
  useScript(src, 'survicate-embed', true)

  useEffect(() => {
    if (!window || !survicateReady || !salesForceContactID) {
      return
    }
    const traits = {
      survey_seen: true,
      user_sectors: ''
    }
    const completedSurvey = {
      Ew: false,
      Ing: false
    }
    if (surveyCompletions.length > 0) {
      surveyCompletions.forEach((survey) => {
        if ('isExpertWitnessSurveyCompleted' in survey && survey.isExpertWitnessSurveyCompleted) {
          if ('sectors' in currentCustomer && hasINVSector(currentCustomer.sectors)) {
            traits.user_sectors = 'investigation'
            traits.survey_seen = false
            completedSurvey.Ew = true
            console.log('Creating investigation survey request')
          } else if (survey?.isExpertWitnessSurveyCompleted) {
            completedSurvey.Ew = true
          }
        } else if ('isInvestigationSurveyCompleted' in survey && survey.isInvestigationSurveyCompleted) {
          if ('sectors' in currentCustomer && hasEWSector(currentCustomer.sectors)) {
            traits.user_sectors = 'expert_witness'
            traits.survey_seen = false
            completedSurvey.Ing = true
            console.log('Creating expert witness survey request')
          } else if (survey?.isInvestigationSurveyCompleted) {
            completedSurvey.Ing = true
          }
        }
      })
    } else {
      if ('sectors' in currentCustomer && hasEWSector(currentCustomer.sectors)) {
        traits.user_sectors = 'expert_witness'
        traits.survey_seen = false
        console.log('No survey has attepted by this. User so loading default survey EW')
      } else if ('sectors' in currentCustomer && hasINVSector(currentCustomer.sectors)) {
        traits.user_sectors = 'investigation'
        traits.survey_seen = false
        console.log('Creating investigation survey request')
      }
    }

    if (completedSurvey.Ew && completedSurvey.Ing) {
      traits.survey_seen = true
      traits.user_sectors = ''
      setSurveyCompleted(true)
      console.log(`Both survey completed by this user ${salesForceContactID}, Simply returning!`)
      return
    }
    // Opts function below is required to set the survey_seen to true or false because this one gets called before the SurvicateReady Event.... but window._sva is called asynchronously so setting this attribute from that block is not recommended

    (function (opts) {
      opts.traits = traits
      console.log(opts.traits)
    })(window._sva = window._sva || {})
    window._sva.setVisitorTraits({
      user_id: salesForceContactID,
      user_sectors: traits.user_sectors,
      survey_seen: traits.survey_seen
    })
  }, [survicateReady, salesForceContactID, surveyCompleted, surveyCompletions, currentCustomer])

  useEffect(() => {
    if (!window || survicateReady) {
      return
    }

    if (window._sva && window._sva.setVisitorTraits) {
      setSurvicateReady(true)
    } else {
      window.addEventListener('SurvicateReady', () => setSurvicateReady(true))
    }
  }, [survicateReady])

  return <span />
}

export default Survicate
