import React from 'react'
import PropTypes from 'prop-types'

import './MainContainer.scss'

function MainContainer ({ children, ...props }) {
  return <section className='main-container' {...props}>{children}</section>
}

MainContainer.propTypes = {
  children: PropTypes.node
}

export default MainContainer
