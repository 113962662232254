import React from 'react'
import PropTypes from 'prop-types'

import MarkdownStandard from '../../UIComponents/MarkdownStandard'

import './FooterContact.scss'

function FooterContact ({ contact }) {
  return (
    <section className='footer-contact'>
      <MarkdownStandard className='footer-contact-content'>{contact}</MarkdownStandard>
    </section>
  )
}

FooterContact.propTypes = {
  business: PropTypes.string.isRequired,
  contact: PropTypes.string.isRequired
}

export default FooterContact
