import React from 'react'
import PropTypes from 'prop-types'

import MarkdownStandard from '../../UIComponents/MarkdownStandard'

import './CourseBodyIntroduction.scss'

function CourseBodyIntroduction ({ course: { overviewInformation } }) {
  return (
    <div className='course-body-introduction'>
      <MarkdownStandard className='course-body-introduction-overview-information'>{overviewInformation}</MarkdownStandard>
    </div>
  )
}

CourseBodyIntroduction.propTypes = {
  course: PropTypes.shape({
    overviewInformation: PropTypes.string.isRequired
  })
}

export default CourseBodyIntroduction
