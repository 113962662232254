import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import Button from '../../UIComponents/Button'

import { historyPush } from '../../utils/location'

import './FeatureButtonLink.scss'

function FeatureButtonLink ({ text, buttonText, buttonLink }) {
  const history = useHistory()
  return (
    <div className='feature-button-link'>
      <span className='feature-button-link-text'>{text}</span>
      <Button type='button' className='feature-button-link-button' level='secondary' clickHandler={() => historyPush({ history, pathname: buttonLink })}>{buttonText}</Button>
    </div>
  )
}

FeatureButtonLink.propTypes = {
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired
}

export default FeatureButtonLink
