import React from 'react'
import Markdown from 'markdown-to-jsx'
import PropTypes from 'prop-types'

import { openWindow } from '../../utils/location'

function open ({ event, href }) {
  event.preventDefault()
  openWindow(href)
}

function Anchor ({ children, href, ...props }) {
  return <a {...props} href={href} onClick={(event) => open({ event, href })}>{children}</a>
}

const markdownOptions = {
  forceBlock: true,
  overrides: {
    a: Anchor
  }
}

function MarkdownStandard ({ children, className = '' }) {
  if (!children) {
    return null
  }

  const classNameConcat = `ui-component-markdown-standard ${className}`.trim()
  return <Markdown className={classNameConcat} options={markdownOptions}>{children}</Markdown>
}

MarkdownStandard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default MarkdownStandard
