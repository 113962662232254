import React, { createContext, useReducer } from 'react'

import reducers from './reducers'
import * as actions from './actions'
import * as selectors from './selectors'

const store = createContext()
const { Provider } = store

const reducersMap = new Map(reducers)

function stateReducer (state, action) {
  const { type } = action
  if (!reducersMap.has(type)) {
    throw new Error(`No reducer for specified type: ${type}`)
  }
  return reducersMap.get(type)({ action, state })
}

function StateProvider ({ children, initialState = null }) {
  const [state, dispatch] = useReducer((state, action) => stateReducer(state, action), initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { store, StateProvider, actions, selectors }
