import React from 'react'
import { Widgets } from '../Widgets'

import FooterContainer from './FooterContainer'
import HeaderContainer from './HeaderContainer'
import IntroContainer from './IntroContainer'
import MainContainer from './MainContainer'

const containerRegistry = new Map([
  ['footerContainer', FooterContainer],
  ['headerContainer', HeaderContainer],
  ['introContainer', IntroContainer],
  ['mainContainer', MainContainer]
])

export function Containers ({ containers = [] }) {
  return containers.map((container, index) => <Container {...container} key={index} />)
}

function Container ({ type, widgets = [], ...props }) {
  if (!containerRegistry.has(type)) {
    console.warn('Unknown container', type)
    return null
  }

  const ContainerComponent = containerRegistry.get(type)
  return (
    <ContainerComponent {...props}>
      <Widgets widgets={widgets} />
    </ContainerComponent>
  )
}

export default Container
