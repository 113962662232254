import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { openWindow } from '../../utils/location'

import config from '../../config'

import './StaticMap.scss'

function openLink ({ event, href }) {
  event.preventDefault()
  openWindow(href)
}

function mapLocation ({ city, country, name, postcode, streetAddress }) {
  return `${streetAddress.join(',+')}, ${city},+${postcode},+${country}`
}

export function mapLink ({ city, country, name, postcode, streetAddress }) {
  const location = mapLocation({ city, country, name, postcode, streetAddress })
  const encodedLocation = location.replace(/\s/g, '+')
  const href = `https://www.google.com/maps/place/${encodedLocation}?region=${country}`
  return href
}

function StaticMap ({ className = '', location: { city, country: countryCode, name, postcode, streetAddress }, zoom = 16, ratio = 0.75 }) {
  const containerElement = useRef(null)
  const [width, setWidth] = useState(10)

  function resizeHandler () {
    if (containerElement.current) {
      setWidth(containerElement.current.clientWidth)
    }
  }

  useEffect(() => {
    if (!window) {
      return
    }

    window.addEventListener('resize', () => resizeHandler())
    resizeHandler()

    return function cleanup () {
      window.removeEventListener('resize', () => resizeHandler())
    }
  }, [setWidth])

  const height = Math.ceil(width * ratio)

  const style = {
    height: `${height}`,
    width: `${width}`
  }

  const staticClassName = `ui-component-static-map ${className}`.trim()
  const scale = 2 // Retina

  const { googleMapsApiKey } = config

  const location = mapLocation({ city, country: countryCode, name, postcode, streetAddress })
  const href = mapLink({ city, country: countryCode, name, postcode, streetAddress })

  // TODO: dynamically update the width and height so that it's always the right size
  const source = new URL('https://maps.googleapis.com/maps/api/staticmap')
  const search = new URLSearchParams()

  search.set('center', location)
  search.set('region', countryCode)
  search.set('zoom', zoom)
  search.set('scale', scale)
  search.set('size', `${width}x${height}`)
  search.set('maptype', 'roadmap')
  search.set('key', googleMapsApiKey)
  search.set('format', 'png')
  search.set('visual_refresh', true)
  search.set('markers', `size:mid|color:0xff0000|${location}`)

  source.search = search.toString()
  const src = source.toString()

  return (
    <a className={staticClassName} href={href} onClick={(event) => openLink({ event, href })} ref={containerElement}>
      <img className='ui-component-static-map-image' src={src} alt={location} style={style} />
    </a>
  )
}

StaticMap.propTypes = {
  className: PropTypes.string,
  location: PropTypes.shape({
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    streetAddress: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  zoom: PropTypes.number,
  ratio: PropTypes.number
}

export default StaticMap
