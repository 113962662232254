import React from 'react'
import PropTypes from 'prop-types'

import { IconNew } from '../../UIComponents/Icon'
import MarkdownStandard from '../../UIComponents/MarkdownStandard'

import './CourseBodyContact.scss'

function CourseBodyContact ({ contactInformationBody, links }) {
  const renderedLinks = links.map(({ icon, text, url = '#' }, index) =>
    <li className='course-body-contact-link-item' key={index}>
      <div className='course-body-contact-link-item-icon-container'>
        <IconNew className='course-body-contact-link-item-icon' icon={icon} title={text} />
      </div>
      <a className='course-body-contact-link-item-link' href={url}>{text}</a>
    </li>
  )

  return (
    <div className='course-body-contact'>
      <MarkdownStandard className='course-body-contact-information'>{contactInformationBody}</MarkdownStandard>
      <ul className='course-body-contact-links'>
        {renderedLinks}
      </ul>
    </div>
  )
}

CourseBodyContact.propTypes = {
  contactInformationBody: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string
  })).isRequired
}

export default CourseBodyContact
