import React from 'react'
import { Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { StateProvider } from './store'
import Businesses from './Businesses'

const helmetContext = {}

function App ({ initialState, history }) {
  return (
    <HelmetProvider context={helmetContext}>
      <StateProvider initialState={initialState}>
        <Router history={history}>
          <Businesses />
        </Router>
      </StateProvider>
    </HelmetProvider>
  )
}

export default App
