import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './Tabs.scss'

function tabClickHandler ({ event, id, setCurrentTab, change }) {
  event.preventDefault()
  setCurrentTab(id)
  change(id)
}

function Tabs ({ tabs, defaultTabId, change = () => {} }) {
  const [currentTab, setCurrentTab] = useState(defaultTabId)

  const buttons = tabs.map(({ id, name }, index) => {
    const className = id === currentTab ? 'ui-component-tabs-list-item-active' : 'ui-component-tabs-list-item'
    return (
      <li className={className} key={index}>
        <button className='ui-component-tabs-list-item-button' data-button-id={id} onClick={(event) => tabClickHandler({ event, id, setCurrentTab, change })}>{name}</button>
      </li>
    )
  })

  const panes = tabs.map(({ id, children }, index) => {
    const className = id === currentTab ? 'ui-component-tabs-panes-pane-active' : 'ui-component-tabs-panes-pane'
    return <div className={className} key={index}>{children}</div>
  })

  return (
    <div className='ui-component-tabs'>
      <ul className='ui-component-tabs-list'>
        {buttons}
      </ul>
      <div className='ui-component-tabs-panes'>
        {panes}
      </div>
    </div>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    children: PropTypes.nodes
  })).isRequired,
  defaultTabId: PropTypes.string.isRequired,
  change: PropTypes.func
}

export default Tabs
