import React from 'react'
import PropTypes from 'prop-types'

import { openWindow } from '../../utils/location'

import './FooterExternalLinks.scss'

function openLink ({ event, url }) {
  event.preventDefault()
  openWindow(url)
}

function FooterExternalLinks ({ externalLinks }) {
  return (
    <>
      <nav className='footer-links-external' aria-label='external links'>
        <ul className='footer-links-list'>
          {externalLinks.map(({ text, url }, index) =>
            <li className='footer-links-external-item' key={index}>
              <a className='footer-links-external-link' href={url} onClick={(event) => openLink({ event, url })}>{text}</a>
            </li>
          )}
        </ul>
      </nav>
    </>
  )
}

const propTypesFooterLink = PropTypes.shape({
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
})

FooterExternalLinks.propTypes = {
  business: PropTypes.string.isRequired,
  externalLinks: PropTypes.arrayOf(propTypesFooterLink).isRequired
}

export default FooterExternalLinks
