import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import './Breadcrumbs.scss'

function Breadcrumbs ({ breadcrumbs }) {
  const links = breadcrumbs.map(({ title, link }, index) => (
    <li className='breadcrumbs-item' key={index}>
      <NavLink className='breadcrumbs-item-link' activeClassName='breadcrumbs-item-link-active' to={link} exact>{title}</NavLink>
    </li>
  ))
  return <nav aria-label='breadcrumbs'><ul className='breadcrumbs'>{links}</ul></nav>
}

Breadcrumbs.propTypes = {
  business: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  })).isRequired
}

export default Breadcrumbs
