import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import FileList from '../../UIComponents/FileList'
import FileInput from '../../UIComponents/FileInput'
import MarkdownStandard from '../../UIComponents/MarkdownStandard'
import Modal from '../../UIComponents/Modal'
import Button from '../../UIComponents/Button'
import UIError from '../../UIComponents/UIError'

import { actions, selectors, store } from '../../store'

import './CourseEventDocumentUpload.scss'
import TickBox from '../../UIComponents/TickBox'

const logger = console

function DocumentList ({ backend, business, courseId, sessionId, existingExplanation }) {
  const { dispatch, state } = useContext(store)
  const [showPopUp, setShowPopUp] = useState(false)
  const [documetIdToDelete, setDocumetIdToDelete] = useState('')
  const documents = selectors.courseEventDocuments({ state, courseId, sessionId })
  const uploads = selectors.documentUpload({ state })

  function showConfirmationPopUp (documentId) {
    setShowPopUp(true)
    setDocumetIdToDelete(documentId)
  }

  function removeDocument () {
    const commonUploadParams = { backend, business, body: { courseId, sessionId, documentId: documetIdToDelete, deleteFile: true } }
    actions.documentDelete({ ...commonUploadParams, dispatch })
    setShowPopUp(false)
    setDocumetIdToDelete('')
  }

  function closePopUp () {
    setShowPopUp(false)
    setDocumetIdToDelete('')
  }

  if (!documents.length && !uploads.size) {
    return null
  }

  const documentsList = documents.map(({ id: documentId, file: { originalname: name, mimetype: type }, signedUrl: { url: href } }) =>
    ({ href, name, type, documentId })
  )

  const pendingList = [...uploads.entries()]
    .filter(([localId, { pending }]) => (pending))
    .map(([localId, { id: documentId, file: { name, type } }]) => ({ documentId, name, type, loading: true }))

  const files = [...documentsList, ...pendingList]

  const errorList = [...uploads.entries()]
    .filter(([localId, { dismissed, error }]) => (error && !dismissed))
    .map(([localId, { file: { name } }]) => (
      <UIError key={localId}
        level='error'
        message={name}
        additional='There was an error uploading your file, please try again later.'
        dismissHandler={() => actions.documentUploadCleanup({ dispatch, localId })}
      />
    ))

  return (
    <div className='course-event-document-upload-existing-documents'>
      <MarkdownStandard className='course-event-document-upload-explanation'>{existingExplanation}</MarkdownStandard>
      <FileList turncatName={true} files={files} clickHandler={showConfirmationPopUp} removeOption={true} removeLabel='Delete' />
      {<Modal active={showPopUp}>
        <div className='course-event-document-delete-modal-container'>
          <h3>Are you sure you want to delete this document?</h3>
          <div className='course-event-document-delete-modal-btn-container'>
            <Button className='course-event-document-delete-modal-btn' clickHandler={removeDocument} level='primary'> Yes</Button>
            <Button className='course-event-document-delete-modal-btn' clickHandler={closePopUp} level='secondary'> No</Button>
          </div>
        </div>
      </Modal>}
      {errorList}
    </div>
  )
}

DocumentList.propTypes = {
  courseId: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
  existingExplanation: PropTypes.string,
  business: PropTypes.string.isRequired,
  backend: PropTypes.string.isRequired
}

function dateDiffInDays (date1, date2) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

function documentUploadValidator (course) {
  const splittedDate = course?.session?.startDate.split('T')[0]
  logger.info('Splitted Date :', splittedDate)
  const courseStartDate = new Date(splittedDate)
  const todayDate = new Date()
  const difference = dateDiffInDays(todayDate, courseStartDate)
  logger.info('Difference days are', difference, ' And condition is: ', difference >= 15)
  return difference >= 15
}

function hasDocumentUploadFlag (course) {
  return course.documentUpload === undefined ? true : course.documentUpload
}

function CourseEventDocumentUpload ({ backend, business, explanation, validFileTypes, course, documents, existingExplanation }) {
  const { dispatch, state } = useContext(store)
  const [files, setFiles] = useState(new Map())
  const [isFileInputDisable, setIsFileInputDisable] = useState(true)
  const fileInputDisclaimer = 'I confirm I have read and complied with the pre-course work requirements'

  function change ({ files: changeFiles }) {
    setFiles(changeFiles)
  }

  function onTickBoxToggle (toggleFileInputButtonDisable) {
    setIsFileInputDisable(!toggleFileInputButtonDisable)
  }

  const { salesForceCourseID: courseId, session: { salesForceCourseEventID: sessionId } } = course
  const displayDocumentUploadComponent = hasDocumentUploadFlag(course) && documentUploadValidator(course)
  logger.info(`debug logs --> documentUploadValidator() : ${documentUploadValidator(course)}, hasDocumentUploadFlag : ${hasDocumentUploadFlag(course)} , &  course.documentUpload value ${course.documentUpload}`)
  logger.info(`courseStart Date ${course.session.startDate.split('T')[0]} & displayDocumentUploadComponent : ${displayDocumentUploadComponent}`)

  useEffect(() => {
    actions.setCourseEventDocuments({ dispatch, courseId, sessionId, documents })
  }, [dispatch, courseId, sessionId, documents])

  useEffect(() => {
    const commonUploadParams = { backend, business, body: { courseId, sessionId } }
    files.forEach((file, localId) => {
      const status = selectors.documentUploadStatus({ state, localId })
      if (!status) {
        actions.documentUpload({ ...commonUploadParams, dispatch, file, localId })
      }
    })
  }, [backend, business, dispatch, files, state, courseId, sessionId])

  const defaultClassName = 'course-event-document-upload'
  const condinationalClassName = !displayDocumentUploadComponent ? 'disable-div' : ''
  const tooltipMessage = 'You can no longer make changes to your pre-course work.<br />Please contact expertwitness@bondsolon.com in case of queries.'

  logger.info(`className ${condinationalClassName} and displayDocumentUploadComponent flag: ${displayDocumentUploadComponent}`)
  return (
    <>
      {hasDocumentUploadFlag(course) && <div data-for="data-tip-id" data-tip={tooltipMessage} data-iscapture="true">
        <div className={`${defaultClassName} ${condinationalClassName}`}>
          <DocumentList courseId={courseId} sessionId={sessionId} existingExplanation={existingExplanation} backend={backend} business={business} />
          <MarkdownStandard className='course-event-document-upload-explanation'>{explanation}</MarkdownStandard>
          <TickBox name='fileUploadTermsConsetCheckbox' required={true} onToggle={onTickBoxToggle} infoText={fileInputDisclaimer}></TickBox>
          <FileInput className='course-event-document-upload-input' name='course-event-document-upload' disabled={isFileInputDisable} multiple change={(files) => change({ files })} fileTypes={validFileTypes} />
        </div>
        {!displayDocumentUploadComponent && <ReactTooltip
          id="data-tip-id"
          place="top"
          type="dark"
          effect="float"
          multiline={true}
        />}
      </div>
      }
    </>
  )
}

CourseEventDocumentUpload.propTypes = {
  backend: PropTypes.string.isRequired,
  business: PropTypes.string.isRequired,
  explanation: PropTypes.string,
  validFileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  course: PropTypes.shape({
    id: PropTypes.string.isRequired,
    salesForceCourseID: PropTypes.string.isRequired,
    session: PropTypes.shape({
      id: PropTypes.string.isRequired,
      salesForceCourseEventID: PropTypes.string.isRequired
    }).isRequired
  }),
  documents: PropTypes.arrayOf(PropTypes.shape({
    file: PropTypes.shape({
      originalname: PropTypes.string.isRequired,
      mimetype: PropTypes.string.isRequired
    }),
    id: PropTypes.string.isRequired,
    signedUrl: PropTypes.shape({
      url: PropTypes.string.isRequired
    }).isRequired
  })).isRequired,
  existingExplanation: PropTypes.string
}
export default CourseEventDocumentUpload
