import { format } from './date'
import get from 'lodash.get'

const isoDateStringRegExp = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+/
const replaceKeyRegExp = /{{\s*([\w.]+)\s*}}/g
const uriRegExp = /((http[s]?|ftp):\/)?\/?([^:/\s]+)((\/\w+)*\/)([\w\-.]+[^#?\s]+)(.*)?(#[\w-]+)?/gi

export function extractUri ({ text }) {
  let uri
  try {
    const match = text.match(uriRegExp)
    uri = match[0]
  } catch (error) {
    console.log('Error parsing URI', text)
  }
  return uri
}

function replaceInstance ({ text, match, data }) {
  const key = match.replace(replaceKeyRegExp, '$1')
  let value = get(data, key)

  if (!value) {
    return text
  }

  if (isoDateStringRegExp.exec(value) !== null) {
    value = format({ date: value, preset: 'dateTimeShort' })
  }
  return text.replace(new RegExp(match, 'g'), value)
}

export function replaceInstances ({ data, text }) {
  const matches = text.match(replaceKeyRegExp)

  if (!matches) {
    return text
  }

  const output = matches.reduce((output, match) =>
    replaceInstance({ data, match, text: output }), text)
  return output
}
