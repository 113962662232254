import React from 'react'
import PropTypes from 'prop-types'
import ViewLink from '../../UIComponents/ViewLink'

import MarkdownStandard from '../../UIComponents/MarkdownStandard'
import Table from '../../UIComponents/Table'
import Button from '../../UIComponents/Button'
import Icon from '../../UIComponents/Icon'
import * as dateUtils from '../../utils/date'
import { getCourseLink } from '../../utils/courseUtil'

import './RecordOfLearningCourseEventsSummary.scss'

function RecordOfLearningCourseEventsSummaryFallback ({ title, fallback }) {
  return (
    <section className='course-events-summary'>
      <h2 className='course-events-summary-title'>{title}</h2>
      <MarkdownStandard>{fallback}</MarkdownStandard>
    </section>
  )
}

function CourseTitleLink ({ course }) {
  const { title, path, salesForceCourseEventID, courseEventType, lmsCourseID } = course
  const to = getCourseLink({ path, salesForceCourseEventID, type: courseEventType, lmsCourseID })
  return <ViewLink className='course-events-link' link={to} text={title}></ViewLink>
}

function getFormatIcon ({ format, courseEventType }) {
  let iconElement
  iconElement = format === 'faceToFace' ? <Icon icon='users' prefix='fa' title='Face to Face' /> : <Icon icon='chalkboard-teacher' prefix='fas' title='Virtual Classroom' />
  if (courseEventType === 'Online Learning') {
    iconElement = <Icon icon="laptop" prefix='fa' title='Online Learning' />
  }
  return <>
    {
      iconElement
    }
  </>
}

function getStatusIcon ({ status }) {
  return <>
    {
      status === 'completed'
        ? <Icon icon='check-circle' prefix='fas' title='Completed' />
        : <Icon icon='clock' prefix='far' title='In-Progress' />
    }
  </>
}

function getFormattedDate ({ date }) {
  return dateUtils.format({ date, preset: 'short' })
}

function getData ({ course, name, certificateDownloadButtonText }) {
  switch (name) {
    case 'course.title':
      return <CourseTitleLink course={course} />
    case 'course.format':
      return getFormatIcon(course)
    case 'course.cpdHours':
      return `${course.cpdHours} hours`
    case 'courseEvent.status':
      return getStatusIcon(course)
    case 'courseEvent.endDate':
      return getFormattedDate({ date: course.completionDate })
    case 'courseEvent.certificateDownload':
      return course.status === 'completed' && <Button type='button' level='primary'>{certificateDownloadButtonText}</Button>
    default:
      return ''
  }
}

function CourseEventsSummary ({ courses, headings, certificateDownloadButtonText }) {
  const onlineLearningCourses = []
  const allCourses = []
  courses.forEach(course => {
    if (course.courseEventType === 'Online Learning') {
      onlineLearningCourses.push(course)
    } else {
      allCourses.push(course)
    }
  })

  const data = allCourses.map((course) => {
    return headings.reduce((data, { name }) => {
      data[name] = getData({ course, name, certificateDownloadButtonText })
      return data
    }, {})
  })

  const onlineCourseHeading = [...headings]
  onlineCourseHeading.length = 3
  const onlineCourseTableData = onlineLearningCourses.map((course) => {
    return onlineCourseHeading.reduce((data, { name }) => {
      data[name] = getData({ course, name, certificateDownloadButtonText })
      return data
    }, {})
  })

  const hasOnlineCourses = !!onlineCourseTableData.length
  const title = hasOnlineCourses ? 'Online Learning' : ''
  return (
    <>
      <Table headings={headings} data={data} />
      { hasOnlineCourses &&
        <>
          <div><h2 className='course-events-summary-title'>{title}</h2></div>
          <Table headings={onlineCourseHeading} data={onlineCourseTableData} />
        </>
      }
    </>
  )
}

function RecordOfLearningCourseEventsSummary ({ title, fallback, recordOfLearningCourseDetailTable, certificateDownloadButtonText, courses, totalCpdHours }) {
  if (!courses.length) {
    return <RecordOfLearningCourseEventsSummaryFallback title={title} fallback={fallback} />
  }

  const headings = recordOfLearningCourseDetailTable.map(({ data: name, heading: label }) => ({ name, label }))

  return (
    <section className='course-events-summary'>
      <div className='course-events-summary-title-container'>
        <div><h2 className='course-events-summary-title'>{title}</h2></div>
        <div className='course-events-total-cpd-wrapper'>
          <div className="course-events-total-cpd">{`${totalCpdHours} hours`}</div>
        </div>
      </div>
      <CourseEventsSummary courses={courses} headings={headings} certificateDownloadButtonText={certificateDownloadButtonText}/>
    </section>
  )
}

RecordOfLearningCourseEventsSummary.propTypes = {
  title: PropTypes.string.isRequired,
  fallback: PropTypes.string.isRequired,
  recordOfLearningCourseDetailTable: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired
  })).isRequired,
  certificateDownloadButtonText: PropTypes.string,
  courses: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    salesForceCourseEventID: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    format: PropTypes.string,
    cpdHours: PropTypes.number,
    status: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    completionDate: PropTypes.string
  })),
  totalCpdHours: PropTypes.number
}

export default RecordOfLearningCourseEventsSummary
