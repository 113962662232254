import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import MarkdownStandard from '../../UIComponents/MarkdownStandard'

import './LatestNews.scss'

function LatestNews ({ title, news }) {
  return (
    <section className='latest-news'>
      <h1 className='latest-news-title'>{title}</h1>
      <ul className='latest-news-list'>
        {news.map(({ path, title: newsTitle, createdAt, body }, index) =>
          <li key={index} className='latest-news-list-item'>
            <h2 className='latest-news-list-item-title'>
              <Link to={path} className='latest-news-list-item-link'>{newsTitle}</Link>
              <span className='latest-news-list-item-date'>{new Date(createdAt).toDateString()}</span>
            </h2>
            <MarkdownStandard className='latest-news-list-item-body'>{body}</MarkdownStandard>
          </li>
        )}
      </ul>
    </section>
  )
}

LatestNews.propTypes = {
  business: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  news: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string.isRequired,
      publishedDate: PropTypes.string.isRequired,
      lastEditedDate: PropTypes.string,
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })
  ).isRequired
}

export default LatestNews
