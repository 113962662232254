import React from 'react'

import Cactus from './Cactus'
import Lovegrass from './Lovegrass'
import Marigold from './Marigold'
import StandardDummy from './StandardDummy'

const layoutRegistry = new Map([
  ['cactus', Cactus],
  ['lovegrass', Lovegrass],
  ['marigold', Marigold]
])

function Layouts (props) {
  const { template } = props
  if (!layoutRegistry.has(template)) {
    // TODO: remove standard dummy
    return <StandardDummy {...props} />
  }

  const LayoutComponent = layoutRegistry.get(template)
  return <LayoutComponent {...props} />
}

export default Layouts
