import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'

import './Search.scss'

function changeHandler ({ event, change, setValue }) {
  const { target: { value } } = event
  setValue(value)
  change(value)
}

function submitHandler ({ event, value, submit }) {
  event.preventDefault()
  submit(value)
}

function Search ({ change, className = '', disabled = false, initialValue = '', placeholder = 'Search', submit }) {
  const [value, setValue] = useState(initialValue)
  const [id] = useState('search-mock-uniqid')
  const labelId = `${id}-label`
  const componentClassName = `ui-component-search ${className}`.trim()
  return (
    <form className={componentClassName} onSubmit={(event) => submitHandler({ event, submit, value })} disabled={disabled}>
      <label className='ui-component-search-box-label' htmlFor={id} id={labelId}>{placeholder}</label>
      <input className='ui-component-search-box' type='text' id={id} placeholder={placeholder} value={value} onChange={(event) => changeHandler({ event, change, setValue })} disabled={disabled} />
      <button type='submit' className='ui-component-search-button' disabled={disabled}>
        <Icon icon='search' className='ui-component-search-button-icon' prefix='fas' title='Search' />
      </button>
    </form>
  )
}

Search.propTypes = {
  change: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  submit: PropTypes.func.isRequired
}

export default Search
