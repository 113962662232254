import React from 'react'
import PropTypes from 'prop-types'

import Widget from '../index'

import './CourseBody.scss'

function CourseBody ({ columns, widgets }) {
  const renderedWidgets = widgets.map((widget, key) =>
    <div className='course-body-widget' key={key}>
      <Widget {...widget} />
    </div>
  )

  let children = renderedWidgets
  if (columns && widgets.length > 1) {
    const perColumn = Math.ceil(widgets.length / 2)
    const firstColumnChildren = renderedWidgets.slice(0, perColumn)
    const secondColumnChildren = renderedWidgets.slice(perColumn)
    children = (
      <div className='course-body-columns'>
        <div className='course-body-column'>{firstColumnChildren}</div>
        <div className='course-body-column'>{secondColumnChildren}</div>
      </div>
    )
  }

  return (
    <div className='course-body'>
      {children}
    </div>
  )
}

CourseBody.propTypes = {
  columns: PropTypes.bool,
  widgets: PropTypes.array.isRequired
}

export default CourseBody
