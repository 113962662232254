import React from 'react'
import PropTypes from 'prop-types'

import './FilterButton.scss'

function changeHandler ({ change, value }) {
  change(value)
}

function FilterButton ({ change, className = '', disabled = false, selected = false, value }) {
  const classNameBase = selected ? 'ui-component-filter-button-selected' : 'ui-component-filter-button'
  const buttonClassName = `${className} ${classNameBase}`.trim()
  return (
    <button type='button' className={buttonClassName} disabled={disabled} onClick={() => changeHandler({ change, value })} value={value}>
      {value}
    </button>
  )
}

FilterButton.propTypes = {
  change: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  value: PropTypes.string.isRequired
}

export default FilterButton
