import React from 'react'
import PropTypes from 'prop-types'
import kebabCase from 'lodash.kebabcase'

import Tabs from '../../UIComponents/Tabs'
import Widget from '../index'

import './CourseEventBody.scss'

function readyTab ({ tab: { columns, name, widgets } }) {
  const renderedWidgets = widgets.map((widget, key) =>
    <div className='course-event-body-tab-pane-widget' key={key}>
      <Widget {...widget} />
    </div>
  )

  let children = renderedWidgets
  if (columns && widgets.length > 1) {
    const perColumn = Math.ceil(widgets.length / 2)
    const firstColumnChildren = renderedWidgets.slice(0, perColumn)
    const secondColumnChildren = renderedWidgets.slice(perColumn)
    children = (
      <div className='course-event-body-tab-pane-columns'>
        <div className='course-event-body-tab-pane-column'>{firstColumnChildren}</div>
        <div className='course-event-body-tab-pane-column'>{secondColumnChildren}</div>
      </div>
    )
  }

  const id = kebabCase(name)

  return {
    name,
    id,
    children: (
      <div className='course-event-body-tab-pane'>
        {children}
      </div>
    )
  }
}

function CourseEventBody ({ tabs }) {
  const readyTabs = tabs.map((tab) => readyTab({ tab }))
  // TODO: something more robust?
  const defaultTabId = kebabCase(readyTabs[0].name)
  return (
    <div className='course-event-body'>
      <Tabs tabs={readyTabs} defaultTabId={defaultTabId} />
    </div>
  )
}

CourseEventBody.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    columns: PropTypes.bool,
    name: PropTypes.string.isRequired,
    widgets: PropTypes.array.isRequired
  }))
}

export default CourseEventBody
