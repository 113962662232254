import React from 'react'
import PropTypes from 'prop-types'

import UIError from '../../UIComponents/UIError'

function WidgetError ({ name, error }) {
  console.warn('Widget error', { name, error })
  return <UIError level='warn' message={name} additional='Error rendering widget' />
}

WidgetError.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.object.isRequired
}

export default WidgetError
