import React from 'react'
import PropTypes from 'prop-types'

import ContentCard from '../../UIComponents/ContentCard'
import MarkdownStandard from '../../UIComponents/MarkdownStandard'

import './ResourceBody.scss'
import { LinkButton } from '../../UIComponents/Button'

function ResourceRelatedContent ({ content, size, title }) {
  const contentItems = content.map(({ path: link, title: newsTitle, image, time, publishedDate, lastEditedDate, contentType }, index) => (
    <ContentCard
      type={contentType}
      variant={size}
      image={image}
      link={link}
      publicationDate={publishedDate}
      editedDate={lastEditedDate}
      time={time}
      title={newsTitle}
      key={index}
    />
  ))

  return (
    <div className='resource-body-related-content'>
      {title && <h3 className='resource-body-related-content-title'>{title}</h3>}
      <div className='resource-body-related-content-items'>
        {contentItems}
      </div>
    </div>
  )
}

function ResourceBody ({ attachments, body, relatedContent }) {
  const contentItems = relatedContent.map((item, index) =>
    <ResourceRelatedContent {...item} key={index} />
  )
  const resourceItemAttachments = attachments.map(({ cta, linkAttachment, fileAttachment }, index) => {
    const href = linkAttachment || fileAttachment
    return <li key={index} className='resource-item-body-content-attachment'><LinkButton href={href}>{cta}</LinkButton></li>
  })

  return (
    <article className='resource-body'>
      <div className='resource-body-content'>
        <MarkdownStandard className='resource-body-content-text'>{body}</MarkdownStandard>
        <ul className='resource-item-body-content-attachments'>
          {resourceItemAttachments}
        </ul>
      </div>
      <div className='resource-body-related'>
        {contentItems}
      </div>
    </article>
  )
}

ResourceBody.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        cta: PropTypes.string.isRequired,
        linkAttachment: PropTypes.string.isRequired
      }),
      PropTypes.shape({
        cta: PropTypes.string.isRequired,
        fileAttachment: PropTypes.string.isRequired
      })
    ])
  ),
  body: PropTypes.string.isRequired,
  relatedContent: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({
      body: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      publishedDate: PropTypes.string.isRequired,
      lastEditedDate: PropTypes.string,
      image: PropTypes.string.isRequired,
      sectors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      tags: PropTypes.arrayOf(PropTypes.string.isRequired),
      time: PropTypes.number.isRequired,
      contentType: PropTypes.string.isRequired
    })),
    size: PropTypes.oneOf(['normal', 'small']).isRequired,
    title: PropTypes.string
  })).isRequired
}

export default ResourceBody
