import React from 'react'
import PropTypes from 'prop-types'
import lowercase from 'lodash.lowercase'
import upperfirst from 'lodash.upperfirst'

import Attribution from '../Attribution'
import Bookmark from '../Bookmark'
import Card from '../Card'
import Icon from '../Icon'
import ViewLink from '../ViewLink'

import * as dateUtils from '../../utils/date'
import { getCourseLink } from '../../utils/courseUtil'

import './CourseCard.scss'

function getStyle (image) {
  const backgroundImage = image ? `url(${image})` : 'none'
  return { backgroundImage }
}

function getSessionFormat ({ session: { format: rawFormat } = {} }) {
  if (!rawFormat) {
    return null
  }

  const format = upperfirst(lowercase(rawFormat))
  return format
}

function CourseCardLarge ({ imageStyle, formattedCpdHours, link, bookmarkIcon, title, sector, format, session, programme }) {
  let cardCaption = 'Course'
  let courseFormat = format
  if (session !== undefined) {
    switch (session.eventType) {
      case 'Conference':
        cardCaption = 'Conference'
        break
      case 'Online Seminar':
        cardCaption = 'Conference'
        if (courseFormat === 'Virtual classroom') {
          courseFormat = 'Virtual attendance'
        }
        break
      case 'Online Learning':
        cardCaption = 'Online Learning'
        session.startDate = null
        courseFormat = 'On-Demand'
        break
      default: cardCaption = 'Course'
        courseFormat = format
        break
    }
  }

  return (
    <Card className='ui-component-course-card-large' size='large' link={link}>
      <div className='ui-component-course-card-large-image' style={imageStyle}>
        {bookmarkIcon && <div className='ui-component-course-card-bookmarked'>{bookmarkIcon}</div>}
      </div>
      <div className='ui-component-course-card-large-content'>
        <dl className='ui-component-course-card-large-image-meta'>
          <dt className='ui-component-course-card-image-meta-key'>Type</dt>
          <dd className='ui-component-course-card-large-image-meta-value ui-component-course-card-large-image-meta-value-type'>{cardCaption}</dd>
          <dt className='ui-component-course-card-image-meta-key'>Time to read/watch</dt>
          <dd className='ui-component-course-card-large-image-meta-value ui-component-course-card-large-image-meta-value-time'>
            <Icon className='ui-component-course-card-large-image-meta-value-clock-icon' icon='clock' prefix='fas' title='Time' />
            {formattedCpdHours}
          </dd>
        </dl>
        <h4 className='ui-component-course-card-large-content-sector'>{sector}</h4>
        <h3 className='ui-component-course-card-large-content-title'>{title}</h3>
        <dl className='ui-component-course-card-additional'>
          {
            courseFormat && <>
              <dt className='ui-component-course-card-additional-key'>Format</dt>
              <dd className='ui-component-course-card-additional-value'>{courseFormat}</dd>
            </>
          }
          {
            programme && <>
              <dt className='ui-component-course-card-additional-key'>Programme</dt>
              <dd className='ui-component-course-card-additional-value'>This course is a unit in <span className="ui-component-course-card-additional-value-highlight">{programme.name}</span></dd>
            </>
          }
        </dl>
        <div className='ui-component-course-card-session'>
          { session && session.startDate &&
            <p className='ui-component-course-card-session-start-date'>Start date {getFormattedStartDate({ session })}</p>
          }
          <ViewLink link={link} />
        </div>
      </div>
    </Card>
  )
}

function CourseCardSmall ({ imageStyle, formattedCpdHours, link, bookmarkIcon, title, sector, format, session, programme }) {
  let cardCaption = 'Course'
  let courseFormat = format
  if (session !== undefined) {
    switch (session.eventType) {
      case 'Conference':
        cardCaption = 'Conference'
        break
      case 'Online Seminar':
        cardCaption = 'Conference'
        if (courseFormat === 'Virtual classroom') {
          courseFormat = 'Virtual attendance'
        }
        break
      case 'Online Learning':
        cardCaption = 'Online Learning'
        session.startDate = null
        break
      default: cardCaption = 'Course'
        courseFormat = format
        break
    }
  }

  return (
    <Card className='ui-component-course-card-small' size='small' link={link}>
      <div className='ui-component-course-card-small-image' style={imageStyle}>
        {bookmarkIcon && <div className='ui-component-course-card-bookmarked'>{bookmarkIcon}</div>}
        <div className='ui-component-course-card-content-holder'>
          <dl className='ui-component-course-card-small-image-meta'>
            <dt className='ui-component-course-card-image-meta-key'>Type</dt>
            <dd className='ui-component-course-card-small-image-meta-value ui-component-course-card-image-meta-value-type'>{cardCaption}</dd>
            <dt className='ui-component-course-card-image-meta-key'>Time to read/watch</dt>
            <dd className='ui-component-course-card-small-image-meta-value ui-component-course-card-image-meta-value-time'>
              <Icon className='ui-component-course-card-small-image-meta-value-clock-icon' icon='clock' prefix='far' title='Time' />
              {formattedCpdHours}
            </dd>
          </dl>

          <div className='ui-component-course-card-small-content'>
            <h3 className='ui-component-course-card-small-content-title'>{title}</h3>
            <h4 className='ui-component-course-card-small-content-sector'>{sector}</h4>
            <dl className='ui-component-course-card-additional'>
              {
                courseFormat && <>
                  <dt className='ui-component-course-card-additional-key'>Format</dt>
                  <dd className='ui-component-course-card-additional-value'>{courseFormat}</dd>
                </>
              }
              {
                programme && <>
                  <dt className='ui-component-course-card-additional-key'>Programme</dt>
                  <dd className='ui-component-course-card-additional-value'>This course is a unit in <span className="ui-component-course-card-additional-value-highlight">{programme.name}</span></dd>
                </>
              }
            </dl>
            <div className='ui-component-course-card-session'>
              {
                session && session.startDate &&
          <p className='ui-component-course-card-session-start-date'>Start date {getFormattedStartDate({ session })}</p>
              }
              <ViewLink link={link} />
            </div>
          </div>
        </div>
      </div>

    </Card>
  )
}

function CourseCardTestimonial ({ testimonials = [] }) {
  if (testimonials.length === 0) {
    return null
  }

  const testimonial = testimonials[0]

  const { text, citationAuthor: author, citationAdditional: additional, citationAvatar: avatar } = testimonial

  const attribution = {
    name: author,
    jobTitle: additional,
    image: avatar
  }

  return (
    <Card className='ui-component-course-card-testimonial' size='small'>
      <blockquote className='ui-component-course-card-testimonial-quote'>
        <p className='ui-component-course-card-testimonial-quote-text'>{text}</p>
        <cite className='ui-component-course-card-testimonial-quote-citation'>
          <Attribution {...attribution} size='medium' />
        </cite>
      </blockquote>
    </Card>
  )
}

function getLink ({ path, session = {} }) {
  const { salesForceCourseEventID = null, lmsCourseID } = session
  return getCourseLink({ path, salesForceCourseEventID, type: session.eventType, lmsCourseID })
}

function getFormattedStartDate ({ session }) {
  const { startDate } = session
  return dateUtils.format({ date: startDate, preset: 'short' })
}

function CourseCard ({ variant, path, cpdHours, image, session, bookmarked = false, format: rawFormat, bookMarkAllowed = false, ...props }) {
  const imageStyle = getStyle(image)
  const formattedCpdHours = `${cpdHours}h`

  // TODO: Something other than this:
  const link = getLink({ path, session })

  const bookmarkIcon = bookMarkAllowed ? <Bookmark bookmarked={bookmarked} /> : null

  const format = getSessionFormat({ session })
  const courseProps = { imageStyle, formattedCpdHours, link, bookmarkIcon, session, format, ...props }

  switch (variant) {
    case 'large':
      return <CourseCardLarge {...courseProps} />
    case 'small':
      return <CourseCardSmall {...courseProps} />
    case 'testimonial':
      return <CourseCardTestimonial {...courseProps} />
    default:
      return null
  }
}

CourseCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sectors: PropTypes.arrayOf(PropTypes.string).isRequired,
  cpdHours: PropTypes.number.isRequired,
  image: PropTypes.string,
  testimonials: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    citationAuthor: PropTypes.string.isRequired,
    citationAdditional: PropTypes.string,
    citationAvatar: PropTypes.string
  })).isRequired,
  match: PropTypes.number,
  session: PropTypes.shape({
    id: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    public: PropTypes.bool.isRequired,
    format: PropTypes.string.isRequired
  }),
  variant: PropTypes.oneOf(['large', 'small', 'testimonial']).isRequired,
  bookmarked: PropTypes.bool,
  bookMarkAllowed: PropTypes.bool,
  programme: PropTypes.shape({
    name: PropTypes.string.isRequired
  })
}

export default CourseCard
