import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'

import './ui-alert.scss'

function UIAlert ({ message, level, additional, icon, iconPrefix, dismissHandler = () => {}, showCloseButton = false }) {
  const className = `ui-alert ui-alert-${level}`
  return (
    <div className={className} data-test-name='ui-alert'>
      <div className='ui-alert-icon'>
        <Icon icon={icon} prefix={iconPrefix} title={level} />
      </div>
      <div className='ui-alert-information'>
        <h3 className='ui-alert-message'>{message}</h3>
        {additional && <p className='ui-alert-additional'>{additional}</p>}
      </div>
      {
        showCloseButton &&
        <button className='ui-alert-dismiss' onClick={dismissHandler}>
          <Icon className='ui-alert-dismiss-icon' icon='times-circle' prefix='far' title='Dismiss' />
        </button>
      }
    </div>
  )
}

UIAlert.propTypes = {
  message: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconPrefix: PropTypes.string.isRequired,
  additional: PropTypes.string,
  dismissHandler: PropTypes.func,
  showCloseButton: PropTypes.bool
}

export default UIAlert
