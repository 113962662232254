import React from 'react'
import PropTypes from 'prop-types'

import { today } from '../../utils/date'
import { replaceInstances } from '../../utils/string'

import './PersonalisedGreeting.scss'

function PersonalisedGreeting ({ personalisedGreetingMessage, customer, showCurrentDate }) {
  const message = replaceInstances({ data: { customer }, text: personalisedGreetingMessage })
  const currentDate = today()

  const classNameDate = showCurrentDate ? 'personalised-greeting-date' : 'personalised-greeting-date-hidden'

  return (
    <section className='personalised-greeting'>
      <h2 className='personalised-greeting-message'>{message}</h2>
      <span className={classNameDate}>{currentDate}</span>
    </section>
  )
}

PersonalisedGreeting.propTypes = {
  business: PropTypes.string.isRequired,
  personalisedGreetingMessage: PropTypes.string.isRequired,
  showCurrentDate: PropTypes.bool.isRequired,
  customer: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    jobTitle: PropTypes.string,
    companyName: PropTypes.string
  })
}

export default PersonalisedGreeting
