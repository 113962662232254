import React from 'react'
import PropTypes from 'prop-types'

import './Loader.scss'

function Loader ({ className = '', loading = false, size = 'large' }) {
  const containerClassNameBase = `ui-component-loader-container-${size}`
  const loaderClassNameBase = `ui-component-loader-${size}`

  const containerClassName = `${containerClassNameBase} ${className}`.trim()
  const loaderClassName = loading ? `${loaderClassNameBase}-loading` : loaderClassNameBase

  return <div className={containerClassName}><div className={loaderClassName} /></div>
}

Loader.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['modal', 'large', 'inline'])
}

export default Loader
