import React from 'react'
import PropTypes from 'prop-types'

import LogoBondSolonSvg from './LogoBondSolonSvg'
import LogoBondSolonNoWilmingtonSvg from './LogoBondSolonNoWilmingtonSvg'

import './LogoBondSolon.scss'

function LogoBondSolon ({ className = '', includeWilmington = false, ...props }) {
  const logoClassName = `logo-bond-solon ${className}`.trim()
  const iconClassName = 'logo-bond-solon-logo'
  const icon = includeWilmington
    ? <LogoBondSolonSvg className={iconClassName} {...props} />
    : <LogoBondSolonNoWilmingtonSvg className={iconClassName} {...props} />
  return (
    <div className={logoClassName}>
      {icon}
    </div>
  )
}

LogoBondSolon.propTypes = {
  className: PropTypes.string,
  includeWilmington: PropTypes.bool
}

export default LogoBondSolon
