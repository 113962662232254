import React from 'react'
import PropTypes from 'prop-types'

import './TickBox.scss'

function TickBox ({ disabled = false, name, required = false, infoText, onToggle }) {
  const disabledClassName = disabled ? '-disabled' : ''
  const className = `ui-component-input-tickbox${disabledClassName}`.trim()

  function onTickBoxToggleEventHandler (event) {
    onToggle(event.target.checked)
  }

  return (
    <div className='ui-component-input'>
      <input type='checkbox' className={className} name={name} required={required} onChange={onTickBoxToggleEventHandler}></input>
      <label htmlFor={name}>{infoText}</label>
    </div>
  )
}

TickBox.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  infoText: PropTypes.string,
  onToggle: PropTypes.func.isRequired
}

export default TickBox
