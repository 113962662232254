function courseEventDocumentsReducer ({ action, state }) {
  const { courseId, sessionId, documents = [] } = action
  const { courseEventDocuments = new Map(), ...rest } = state
  courseEventDocuments.set(`${courseId}.${sessionId}`, documents)
  return { ...rest, courseEventDocuments }
}

const courseEventDocuments = ['course event documents', courseEventDocumentsReducer]

function documentUploadCleanupReducer ({ action, state }) {
  const { localId } = action
  const { documentUpload = new Map(), ...otherState } = state
  const existingUpload = documentUpload.get(localId)
  const { file, error } = existingUpload
  documentUpload.set(localId, { dismissed: true, error, ok: false, pending: false, file })
  return { ...otherState, documentUpload }
}

const documentUploadCleanup = ['document upload cleanup', documentUploadCleanupReducer]

function documentUploadFailureReducer ({ action, state }) {
  const { localId, error } = action
  const { documentUpload = new Map(), ...otherState } = state
  const existingUpload = documentUpload.get(localId)
  const { file } = existingUpload
  documentUpload.set(localId, { dismissed: false, error, ok: false, pending: false, file })
  return { ...otherState, documentUpload }
}

const documentUploadFailure = ['document upload failure', documentUploadFailureReducer]

function documentUploadInitiateReducer ({ action, state }) {
  const { localId, file } = action
  const { documentUpload = new Map(), ...otherState } = state
  documentUpload.set(localId, { dismissed: false, error: null, ok: false, pending: true, file })
  return { ...otherState, documentUpload }
}

const documentUploadInitiate = ['document upload initiate', documentUploadInitiateReducer]

function documentUploadSuccessReducer ({ action, state }) {
  const { localId } = action
  const { documentUpload = new Map(), ...otherState } = state
  const existingUpload = documentUpload.get(localId)
  const { file } = existingUpload
  documentUpload.set(localId, { dismissed: true, error: null, ok: true, pending: false, file })
  return { ...otherState, documentUpload }
}

const documentUploadSuccess = ['document upload success', documentUploadSuccessReducer]

function modalActiveReducer ({ action, state }) {
  const { active } = action
  return { ...state, modal: { active } }
}

const modalActive = ['modal active', modalActiveReducer]

function profileMenuHideReducer ({ state }) {
  return { ...state, profileMenu: { visible: false } }
}

const profileMenuHide = ['profile menu hide', profileMenuHideReducer]

function profileMenuShowReducer ({ state }) {
  return { ...state, profileMenu: { visible: true } }
}

const profileMenuShow = ['profile menu show', profileMenuShowReducer]

function sideBarMenuHideReducer ({ state }) {
  return { ...state, sideBarMenu: { visible: false } }
}

const sideBarMenuHide = ['sidebar menu hide', sideBarMenuHideReducer]

function sideBarMenuShowReducer ({ state }) {
  return { ...state, sideBarMenu: { visible: true } }
}

const sideBarMenuShow = ['sidebar menu show', sideBarMenuShowReducer]

function routePendingReducer ({ state }) {
  return { ...state, routePending: true }
}

const routePending = ['route pending', routePendingReducer]

function routeUpdateReducer ({ action }) {
  const { data } = action
  return { ...data }
}

const routeUpdate = ['route update', routeUpdateReducer]

function ssoTokenExchangeFailureReducer ({ action, state }) {
  return { ...state, ssoTokenExchange: { ok: false, error: action, redirect: null } }
}

const ssoTokenExchangeFailure = ['sso token exchange failure', ssoTokenExchangeFailureReducer]

function ssoTokenExchangeSuccessReducer ({ state }) {
  return { ...state, ssoTokenExchange: { ok: true, error: null, redirect: null } }
}

const ssoTokenExchangeSuccess = ['sso token exchange success', ssoTokenExchangeSuccessReducer]

function ssoTokenExchangeRedirectReducer ({ action, state }) {
  const { redirect } = action
  return { ...state, ssoTokenExchange: { ok: false, error: null, redirect } }
}

const ssoTokenExchangeRedirect = ['sso token exchange redirect', ssoTokenExchangeRedirectReducer]

function ssoSignOutFailureReducer ({ action, state }) {
  return { ...state, ssoSignOut: { ok: false, error: action } }
}

const ssoSignOutFailure = ['sso sign out failure', ssoSignOutFailureReducer]

function ssoSignOutSuccessReducer ({ state }) {
  return { ...state, ssoSignOut: { ok: true, error: null } }
}

const ssoSignOutSuccess = ['sso sign out success', ssoSignOutSuccessReducer]

export default [
  courseEventDocuments,
  documentUploadCleanup,
  documentUploadFailure,
  documentUploadInitiate,
  documentUploadSuccess,
  modalActive,
  profileMenuHide,
  profileMenuShow,
  sideBarMenuHide,
  sideBarMenuShow,
  routePending,
  routeUpdate,
  ssoTokenExchangeFailure,
  ssoTokenExchangeSuccess,
  ssoTokenExchangeRedirect,
  ssoSignOutFailure,
  ssoSignOutSuccess
]
