import React from 'react'
import PropTypes from 'prop-types'

import './HeroSlider.scss'

function HeroSlider ({ heroImages }) {
  const images = heroImages.map(({ image }, index) =>
    <div style={{ backgroundImage: `url("${image}")` }} key={index} className='hero-slider-image' />
  )
  return (
    <div className='hero-slider'>
      {images}
    </div>
  )
}

HeroSlider.propTypes = {
  business: PropTypes.string.isRequired,
  heroImages: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired
    })
  )
}

export default HeroSlider
