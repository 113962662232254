import React from 'react'
import PropTypes from 'prop-types'

import './FooterInternalLinks.scss'

function FooterInternalLinks ({ internalLinks, openCallback }) {
  return (
    <>
      <nav className='footer-links-internal' aria-label='internal links'>
        <ul className='footer-links-list'>
          {internalLinks.map(({ text, url }, index) =>
            <li className='footer-links-internal-item' key={index}>
              <a className='footer-links-internal-link' href={url} onClick={(event) => openCallback({ event, url })}>{text}</a>
            </li>
          )}
        </ul>
      </nav>
    </>
  )
}

const propTypesFooterInternalLink = PropTypes.shape({
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
})

FooterInternalLinks.propTypes = {
  business: PropTypes.string.isRequired,
  internalLinks: PropTypes.arrayOf(propTypesFooterInternalLink).isRequired
}

export default FooterInternalLinks
