import React from 'react'
import PropTypes from 'prop-types'

import MarkdownStandard from '../../UIComponents/MarkdownStandard'

import './FooterAddress.scss'

function FooterAddress ({ address }) {
  return (
    <section className='footer-address'>
      <MarkdownStandard className='footer-address-content'>{address}</MarkdownStandard>
    </section>
  )
}

FooterAddress.propTypes = {
  business: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired
}

export default FooterAddress
