import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'

import './Bookmark.scss'

function Bookmark ({ bookmarked = false }) {
  const prefix = bookmarked ? 'fas' : 'far'
  const title = bookmarked ? 'Bookmarked' : 'Not bookmarked'
  return (
    <div className='ui-component-bookmark'>
      <Icon className='ui-component-bookmark-icon' icon='bookmark' prefix={prefix} title={title} />
    </div>
  )
}

Bookmark.propTypes = {
  bookmarked: PropTypes.bool
}

export default Bookmark
