import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import Container from '../../Containers'

import LogoBondSolon from '../../UIComponents/LogoBondSolon'

import './Cactus.scss'

function renderLogo ({ business }) {
  switch (business) {
    case 'ica':
      return null
    case 'bondSolon':
    default:
      return <LogoBondSolon className='cactus-header-logo-icon' includeWilmington />
  }
}

function calculateStyles ({ backgroundImage: image, variation }) {
  const backgroundImage = image ? `url(${image})` : 'none'
  let cactusStyle = {}
  let cactusIntroContainerStyle = {}
  switch (variation) {
    case 'intro-highlight':
      cactusStyle = { backgroundImage }
      break
    default:
      cactusIntroContainerStyle = { backgroundImage }
  }
  return { cactusStyle, cactusIntroContainerStyle }
}

function Cactus ({ business, model }) {
  const { containers, variation, backgroundImage } = model
  const {
    headerContainer,
    introContainer,
    mainContainer
  } = containers.reduce((acc, container) => ({ ...acc, [container.type]: container }), {})

  let className = 'cactus'
  let headerClassName = `${className}-header`
  let headerLogoClassName = `${headerClassName}-logo`
  let contentClassName = `${className}-content`

  if (variation) {
    className = `${className}-${variation}`
    headerClassName = `${headerClassName}-${variation}`
    headerLogoClassName = `${headerLogoClassName}-${variation}`
    contentClassName = `${contentClassName}-${variation}`
  }

  const { cactusStyle, cactusIntroContainerStyle } = calculateStyles({ backgroundImage, variation })
  const logo = renderLogo({ business })

  return (
    <div className={className} style={cactusStyle}>
      <div className={headerClassName}>
        <Link className={headerLogoClassName} to='/'>{logo}</Link>
        <Container {...headerContainer} />
      </div>
      <div className={contentClassName}>
        <Container {...introContainer} style={cactusIntroContainerStyle} />
        <Container {...mainContainer} />
      </div>
    </div>
  )
}

Cactus.propTypes = {
  model: PropTypes.shape({
    containers: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired
      })
    ).isRequired,
    variation: PropTypes.string,
    backgroundImage: PropTypes.string
  }).isRequired
}

export default Cactus
