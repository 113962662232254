import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import lowercase from 'lodash.lowercase'
import striptags from 'striptags'

import { LinkButton, SimpleLinkButton } from '../../UIComponents/Button'
import Card from '../../UIComponents/Card'
import FileList from '../../UIComponents/FileList'
import MarkdownStandard from '../../UIComponents/MarkdownStandard'
import StaticMap, { mapLink } from '../../UIComponents/StaticMap'

import * as dateUtils from '../../utils/date'
import { createObjectURL, revokeObjectURL, openWindow } from '../../utils/location'
import { isEmpty } from '../../utils/courseUtil'
import './CourseEventBodyInformation.scss'

function getStartDate (course) {
  const { title, session } = course
  const { startDate, calendarEvent } = session
  const formattedStartDate = dateUtils.format({ date: startDate, preset: 'dateTime' })
  return <>
    <CalendarButton iconName='FaCalendar' iconTitle='courseStartDate' calendarEvent={calendarEvent} title={title}> {formattedStartDate}</CalendarButton>
  </>
}

function getEndDate (course) {
  const { title, session } = course
  const { endDate, calendarEvent } = session
  const formattedEndDate = dateUtils.format({ date: endDate, preset: 'dateTime' })
  return <>
    <CalendarButton iconName='FaCalendar' iconTitle='courseEndDate' calendarEvent={calendarEvent} title={title}> {formattedEndDate}</CalendarButton>
  </>
}

function structureVenueLocation ({ venue }) {
  if (!venue) {
    return null
  }

  const { city, name, postcode, streetAddress } = venue
  return (
    <div className='course-event-body-information-structured-location'>
      <span className='course-event-body-information-structured-location-name'>{name}</span><br />
      <span className='course-event-body-information-structured-location-street-address'>{streetAddress.map((address, index) => (
        <Fragment key={index}><span>{address}</span><br /></Fragment>
      ))}</span>
      <span className='course-event-body-information-structured-location-city'>{city}</span><br />
      <span className='course-event-body-information-structured-location-postcode'>{postcode}</span>
    </div>
  )
}

function openLink ({ event, href }) {
  event.preventDefault()
  openWindow(href)
}

function map ({ additionalInformation = '', session }) {
  const { venue } = session

  if (!venue) {
    return null
  }

  const { city, country, name, postcode, streetAddress } = venue
  const location = { city, country, name, postcode, streetAddress }
  const href = mapLink({ city, country, name, postcode, streetAddress })

  return (
    <div className='course-event-body-information-map'>
      <StaticMap className='course-event-body-information-map-image' location={location} />
      <div className='course-event-body-information-map-additional'>
        <MarkdownStandard className='course-event-body-information-map-additional-text'>{additionalInformation}</MarkdownStandard>
        <a href={href} onClick={(event) => openLink({ event, href })} className='course-event-body-information-map-additional-link'>Open map</a>
      </div>
    </div>
  )
}

function structureOnlinePlatform ({ onlinePlatform, onlineSessionDetails, additionalInformation = '', onlineSessionLinkButtonText }) {
  if (!onlinePlatform) {
    return null
  }

  const meetingLink = striptags(onlineSessionDetails)
  const content = `${onlinePlatform}

${meetingLink}

${additionalInformation}`

  return <>
    <MarkdownStandard className='course-event-body-information-value-additional'>{content}</MarkdownStandard>
    <LinkButton href={meetingLink} level='primary'>{onlineSessionLinkButtonText}</LinkButton>
  </>
}

function structureProgrammeAssessment ({ course }) {
  const { assessmentInformation, programme } = course

  if (!assessmentInformation || !programme) {
    return null
  }
  // if course does not have assessment details dont show assessment information in overview tab.
  if (!(course && course.courseMapDetails && course.courseMapDetails.hasAssessment)) {
    return null
  }

  // TODO:guidanceNotes will be removed, later remove referece from here
  let { guidanceNotes, guidanceNotes1 } = programme
  if (isEmpty(guidanceNotes)) {
    if (isEmpty(guidanceNotes1)) {
      return null
    }
  } else if (!isEmpty(guidanceNotes1) && guidanceNotes1.length) {
    guidanceNotes1.push(guidanceNotes)
  } else {
    guidanceNotes1 = [guidanceNotes]
  }

  if (!guidanceNotes1.length) {
    return null
  }

  let files = []
  let hasFiles = false
  files = guidanceNotes1.map(({ url: href, originalFilename: name, mimeType: type }) =>
    ({ href, name, type }))
  hasFiles = !!files.length

  return (
    <>
      <MarkdownStandard className='course-event-body-information-value-additional'>{assessmentInformation}</MarkdownStandard>
      {hasFiles && <FileList files={files} />}
    </>
  )
}

function getInformationItemData ({ onlineSessionLinkButtonText, additionalInformation, course, session, type }) {
  switch (type) {
    case 'cpdHours':
      return course.cpdHours
    case 'startTime':
      return getStartDate(course)
    case 'endTime':
      return getEndDate(course)
    case 'courseFormat':
      return (
        <span className='course-event-body-information-format-additional'>
          {(session?.eventType === 'Online Seminar' && session?.format === 'virtualClassroom') ? 'Virtual attendance' : lowercase(course.session.format)}
        </span>
      )
    case 'registrationTime':
      return session.registrationDate ? dateUtils.format({ date: session.registrationDate, preset: 'time' }) : ''
    case 'location':
      return structureVenueLocation(session)
    case 'map':
      return map({ additionalInformation, session })
    case 'onlinePlatform':
      return structureOnlinePlatform({ ...session, additionalInformation, onlineSessionLinkButtonText })
    case 'conferencePlatform':
      return <MarkdownStandard className='course-event-body-information-value-additional'>{session?.onlineSessionDetails}</MarkdownStandard>
    case 'programmeAssessment':
      return structureProgrammeAssessment({ course })
    case 'dressCode':
      return course.dressCode ? <MarkdownStandard className='course-event-body-information-value-additional'>{course.dressCode}</MarkdownStandard> : null
    case 'custom':
    default:
      return <MarkdownStandard className='course-event-body-information-value-additional'>{additionalInformation}</MarkdownStandard>
  }
}

function CourseEventBodyInformationItem ({ label, type, onlineSessionLinkButtonText, additionalInformation, course, session }) {
  const data = getInformationItemData({ onlineSessionLinkButtonText, additionalInformation, course, session, type })

  if (data === null) {
    return null
  }
  return (
    <li className='course-event-body-information-item'>
      <h5 className='course-event-body-information-key'>{label}</h5>
      <div className='course-event-body-information-value'>{data}</div>
    </li>
  )
}

function CalendarButton ({ calendarEvent, children, title, iconName, iconTitle }) {
  const [href, setHref] = useState('#')

  useEffect(() => {
    if (!calendarEvent) {
      return
    }

    const blob = new Blob([calendarEvent], { type: 'text/calendar' })
    const href = createObjectURL(blob)
    setHref(href)

    return () => revokeObjectURL(href)
  }, [calendarEvent, setHref])

  if (!calendarEvent) {
    return null
  }

  const fileTitle = `${title}.ics`

  return (
    <>
      {iconName
        ? <SimpleLinkButton href={href} download={fileTitle} iconName={iconName} iconTitle={iconTitle}>{children}</SimpleLinkButton>
        : <div className='course-event-body-calendar-button'>
          <LinkButton href={href} level='primary' download={fileTitle}>{children}</LinkButton>
        </div>}
    </>
  )
}

function CourseEventBodyInformation ({ onlineSessionLinkButtonText, addToCalendarButtonText, information, course }) {
  const { title, session } = course
  const { calendarEvent, format } = session

  let eventSpecificInformationItems = information.filter(info => info.formats.includes(format))
  switch (session?.eventType) {
    case 'Online Seminar':
      eventSpecificInformationItems = eventSpecificInformationItems.filter(info => info.type !== 'onlinePlatform').filter(info => info.label !== 'Breaks')
      break
    case 'Conference':
      break
    default:
      eventSpecificInformationItems = eventSpecificInformationItems.filter(info => info.type !== 'conferencePlatform')
      break
  }

  const informationItems = eventSpecificInformationItems.map((item, index) => <CourseEventBodyInformationItem {...item} onlineSessionLinkButtonText={onlineSessionLinkButtonText} course={course} session={session} key={index} />)

  return (
    <Card size='block' className='course-event-body-information'>
      <ul className='course-event-body-information-list'>
        {informationItems}
      </ul>
      <CalendarButton calendarEvent={calendarEvent} title={title}>{addToCalendarButtonText}</CalendarButton>
    </Card>
  )
}

CourseEventBodyInformation.propTypes = {
  course: PropTypes.shape({
    session: PropTypes.shape({
      endDate: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      calendarEvent: PropTypes.string
    }).isRequired,
    title: PropTypes.string.isRequired,
    programme: PropTypes.shape({
      name: PropTypes.string.isRequired,
      guidanceNotes: PropTypes.shape({
        mimeType: PropTypes.string.isRequired,
        originalFilename: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    }),
    assessmentInformation: PropTypes.string
  }).isRequired,
  onlineSessionLinkButtonText: PropTypes.string,
  addToCalendarButtonText: PropTypes.string.isRequired,
  information: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    formats: PropTypes.arrayOf(PropTypes.string).isRequired,
    additionalInformation: PropTypes.string
  })).isRequired
}

export default CourseEventBodyInformation
