const EWLA3Codes = ['EX1', 'EX2', 'EX3', 'EX4', 'EX6', 'EX8']
const INVLA3Codes = ['EP1', 'EP2', 'EP7', 'IN1', 'IN2', 'IN3', 'IN4', 'IN5', 'IN6', 'IN7', 'IN8', 'IN9', 'TC1', 'TC10', 'HS1']

export function hasINVSector (sectors) {
  return !!sectors?.filter(sector => INVLA3Codes.includes(sector)).length
}

export function hasEWSector (sectors) {
  return !!sectors?.filter(sector => EWLA3Codes.includes(sector)).length
}

export function hasINVTags (tags) {
  let flag = false
  if (tags?.length) {
    tags.forEach(element => {
      if (element.toLowerCase().includes('bsl-inv-')) {
        flag = true
      }
    })
  }
  return flag
}
