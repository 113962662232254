import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../UIComponents/Icon'
import MarkdownStandard from '../../UIComponents/MarkdownStandard'

import './IconContent.scss'

function IconContent ({ items }) {
  const icons = items.map(({ content, icon }, index) =>
    <li className='icon-content-list-item' key={index}>
      <Icon className='icon-content-list-item-icon' icon={icon} prefix='fas' title='Icon' role='presentation' />
      <MarkdownStandard className='icon-content-list-item-content'>{content}</MarkdownStandard>
    </li>
  )
  return <ul className='icon-content-list'>{icons}</ul>
}

IconContent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
  }))
}

export default IconContent
