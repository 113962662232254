import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import Icon from '../Icon'

import { isExternal } from '../../utils/location'

import './ViewLink.scss'

function ExternalLink ({ viewLinkClassName, link, text, description = text }) {
  return (
    <a className={viewLinkClassName} href={link} aria-label={description} rel='noopener noreferrer' target='_blank'>
      {text}
      <Icon className='ui-component-view-link-icon' icon='chevron-right' prefix='fas' role='presentation' title={text} />
    </a>
  )
}

function InternalLink ({ viewLinkClassName, link, text, description = text }) {
  return (
    <Link className={viewLinkClassName} to={link} aria-label={description}>
      {text}
      <Icon className='ui-component-view-link-icon' icon='chevron-right' prefix='fas' role='presentation' title={text} />
    </Link>
  )
}

function ViewLink ({ className = '', description, link, text = 'View', variant = 'normal' }) {
  const viewLinkClassName = `ui-component-view-link-${variant} ${className}`.trim()
  return isExternal(link)
    ? <ExternalLink viewLinkClassName={viewLinkClassName} link={link} text={text} description={description} />
    : <InternalLink viewLinkClassName={viewLinkClassName} link={link} text={text} description={description} />
}

ViewLink.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string.isRequired,
  text: PropTypes.string,
  variant: PropTypes.oneOf(['normal', 'inherit'])
}

export default ViewLink
