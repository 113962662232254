import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export function useDebounce (value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay)
    return () => clearTimeout(handler)
  }, [delay, value])

  return debouncedValue
}

export function useScript (src, id, async = true) {
  useEffect(() => {
    if (!window || !src) {
      return
    }

    const existing = document.getElementById(id)
    if (existing && existing.src === src) {
      return
    }

    const script = document.createElement('script')
    script.src = src
    script.id = id
    script.async = async
    document.body.appendChild(script)
  }, [src, id, async])
}

export function useEscapeKey (handler) {
  useEffect(() => {
    window.addEventListener('keydown', (event) => handler(event))
    return () => window.removeEventListener('keydown', (event) => handler(event))
  }, [handler])
}

export function useLocationChange (handler) {
  const location = useLocation()
  const { key: currentKey } = location
  const [key, setKey] = useState(currentKey)
  useEffect(() => {
    if (currentKey !== key) {
      setKey(currentKey)
      return handler(location)
    }
  }, [handler, location, currentKey, key, setKey])
}
