import React from 'react'
import PropTypes from 'prop-types'

import MarkdownStandard from '../../UIComponents/MarkdownStandard'
import Table from '../../UIComponents/Table'
import Icon from '../../UIComponents/Icon'
import * as dateUtils from '../../utils/date'

import './RecordOfLearningProgrammesSummary.scss'

function RecordOfLearningProgrammesSummaryFallback ({ title, fallback }) {
  return (
    <section className='programmes-summary'>
      <h2 className='programmes-summary-title'>{title}</h2>
      <MarkdownStandard>{fallback}</MarkdownStandard>
    </section>
  )
}

function getStatusIcon ({ status }) {
  return <>
    {
      status === 'completed'
        ? <Icon icon='check-circle' prefix='fas' title='Completed' />
        : <Icon icon='clock' prefix='far' title='In-Progress' />
    }
  </>
}

function getFormattedDate ({ date }) {
  return dateUtils.format({ date, preset: 'short' })
}

function getData ({ programme, name }) {
  switch (name) {
    case 'programme.title':
      return programme.name
    case 'programme.status':
      return getStatusIcon(programme)
    case 'programme.endDate':
      return programme.completionDate ? getFormattedDate({ date: programme.completionDate }) : ''
    default:
      return ''
  }
}

function ProgrammesSummary ({ programmes, headings }) {
  const data = programmes.map((programme) => {
    return headings.reduce((data, { name }) => {
      data[name] = getData({ programme, name })
      return data
    }, {})
  })

  return (
    <>
      <Table headings={headings} data={data} />
    </>
  )
}

function RecordOfLearningProgrammesSummary ({ title, fallback, recordOfLearningProgrammeDetailTable, programmes }) {
  if (!programmes.length) {
    return <RecordOfLearningProgrammesSummaryFallback title={title} fallback={fallback} />
  }

  const headings = recordOfLearningProgrammeDetailTable.map(({ data: name, heading: label }) => ({ name, label }))

  return (
    <section className='programmes-summary'>
      <div className='programmes-summary-title-container'>
        <div><h2 className='programmes-summary-title'>{title}</h2></div>
      </div>
      <ProgrammesSummary programmes={programmes} headings={headings}/>
    </section>
  )
}

RecordOfLearningProgrammesSummary.propTypes = {
  title: PropTypes.string.isRequired,
  fallback: PropTypes.string.isRequired,
  recordOfLearningProgrammeDetailTable: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired
  })).isRequired,
  programmes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    completionDate: PropTypes.string
  }))
}

export default RecordOfLearningProgrammesSummary
