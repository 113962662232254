import { useContext, useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { selectors, store } from '../../store'

const censoredSearchParams = ['code', 'state']

function censorLocation ({ pathname, search: rawSearchString }) {
  const searchParams = new URLSearchParams(rawSearchString)
  censoredSearchParams.forEach((key) => searchParams.delete(key))
  const searchString = searchParams.toString()
  const search = searchString ? `?${searchString}` : ''
  return `${pathname}${search}`
}

function GoogleAnalytics ({ debug, trackingID }) {
  const { state } = useContext(store)
  const location = useLocation()

  // Initialise
  useEffect(() => {
    ReactGA.initialize(trackingID, { debug })
  }, [trackingID, debug])

  // Set the current user
  const salesForceContactID = selectors.currentCustomersalesForceContactID({ state })
  useEffect(() => {
    if (salesForceContactID) {
      ReactGA.set({ dimension1: salesForceContactID, userId: salesForceContactID })
    }
  }, [salesForceContactID])

  // Location change
  const pageLocation = censorLocation(location)
  useEffect(() => {
    if (salesForceContactID) {
      ReactGA.set({ dimension1: salesForceContactID, userId: salesForceContactID })
    }
    ReactGA.pageview(pageLocation)
  }, [pageLocation, salesForceContactID])

  return null
}

GoogleAnalytics.propTypes = {
  debug: PropTypes.bool,
  trackingID: PropTypes.string.isRequired
}

export default GoogleAnalytics
