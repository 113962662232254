import React from 'react'
import PropTypes from 'prop-types'
import startcase from 'lodash.startcase'

import Bookmark from '../../UIComponents/Bookmark'
import Head from '../../UIComponents/Head'
import Icon from '../../UIComponents/Icon'

import * as dateUtils from '../../utils/date'
import * as durationUtils from '../../utils/duration'

import './NewsArticleHeader.scss'

function getStyle (image) {
  const backgroundImage = image ? `url(${image})` : 'none'
  return { backgroundImage }
}

function getDate ({ lastEditedDate, publishedDate }) {
  let content
  if (lastEditedDate) {
    const formattedEditDate = dateUtils.format({ date: lastEditedDate, preset: 'short' })
    content = `Last edited on: ${formattedEditDate}`
  } else {
    const formattedPublishedDate = dateUtils.format({ date: publishedDate, preset: 'short' })
    content = `Published on: ${formattedPublishedDate}`
  }
  return content
}

function NewsArticleHeader ({ contentType, publishedDate, lastEditedDate, image, sectors, tags, time, title, bookMarkAllowed = false }) {
  const imageStyle = getStyle(image)
  const formattedTime = durationUtils.format({ duration: time })
  const dateText = getDate({ lastEditedDate, publishedDate })
  const contentTypeText = startcase(contentType)
  return (
    <header className='news-article-header'>
      <Head title={title} image={image} />
      <div className='news-article-header-image' style={imageStyle} />
      <div className='news-article-header-content'>
        <div className='news-article-header-content-titles'>
          <h1 className='news-article-header-content-title'>{title}</h1>
          <h2 className='news-article-header-content-type'>{contentTypeText}</h2>
        </div>
        {bookMarkAllowed && <Bookmark />}
      </div>
      <dl className='news-article-header-meta'>
        <dt className='news-article-header-meta-key news-article-header-meta-time'>Time to read/watch</dt>
        <dd className='news-article-header-meta-value news-article-header-meta-time'>
          <Icon className='news-article-header-meta-value-clock-icon' icon='clock' prefix='fas' title='Time' />
          {formattedTime}
        </dd>
        <dt className='news-article-header-meta-key'>Publication or Last Edited date</dt>
        <dd className='news-article-header-meta-value'>{dateText}</dd>
      </dl>
    </header>
  )
}

NewsArticleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  publishedDate: PropTypes.string.isRequired,
  lastEditedDate: PropTypes.string,
  image: PropTypes.string.isRequired,
  sectors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired),
  time: PropTypes.number.isRequired,
  bookMarkAllowed: PropTypes.bool
}

export default NewsArticleHeader
