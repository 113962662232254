import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import uniqid from 'uniqid'
import mime from 'mime-types'

import Button from '../Button'

import './FileInput.scss'

const fullFileTypes = new Map([
  ['csv', ['csv']],
  ['doc', ['doc', 'docx']],
  ['pdf', ['pdf']],
  ['ppt', ['ppt', 'pptx']],
  ['txt', ['txt']],
  ['xls', ['xls', 'xlsx']]
])

const fileTypesList = [...fullFileTypes.keys()]

function changeHandler (event, change, files) {
  event.preventDefault()
  event.target.value = ''
  change(files)
}

function getAcceptList ({ fileTypes }) {
  const accept = [].concat(...fileTypes
    .map(type => fullFileTypes.has(type) ? fullFileTypes.get(type) : null)
    .filter(type => Boolean(type))
  ).map(type => {
    const mimetype = mime.lookup(type)
    return mimetype ? [`.${type}`, mimetype] : null
  }).filter(type => Boolean(type))
  return [].concat(...accept)
}

function FileInputListContainer ({ files, showFileList }) {
  if (!showFileList) {
    return null
  }

  const fileList = [...files.values()].map(({ name, type }, index) =>
    <li className='ui-component-file-input-filelist-item' key={index}>{name}</li>
  )

  const renderedFileList = fileList.length
    ? <ul className='ui-component-file-input-filelist-list'>{fileList}</ul>
    : <p className='ui-component-file-input-filelist-fallback'>No file selected</p>

  return (
    <div className='ui-component-file-input-filelist'>
      {renderedFileList}
    </div>
  )
}

function FileInput ({ change, disabled = false, fileTypes, multiple = false, name, required = false, showFileList = false }) {
  const [dragging, setDragging] = useState(false)
  const [files, setFiles] = useState(new Map())
  const inputElement = useRef(null)

  useEffect(() => {
    const { current } = inputElement
    if (!inputElement.current) {
      return
    }

    current.addEventListener('dragenter', () => setDragging(true))
    current.addEventListener('dragover', () => setDragging(true))
    current.addEventListener('dragleave', () => setDragging(false))
    current.addEventListener('drop', () => setDragging(false))

    return () => {
      current.removeEventListener('dragenter', () => setDragging(true))
      current.removeEventListener('dragover', () => setDragging(true))
      current.removeEventListener('dragleave', () => setDragging(false))
      current.removeEventListener('drop', () => setDragging(false))
    }
  }, [inputElement, setDragging])

  const containerClassName = dragging ? 'ui-component-file-input-dragging' : 'ui-component-file-input'

  const disabledClassName = disabled ? '-disabled' : ''
  const labelClassName = dragging ? 'ui-component-file-input-label-dragging' : `ui-component-file-input-label${disabledClassName}`.trim()
  const inputClassName = `ui-component-file-input-input${disabledClassName}`.trim()

  const acceptList = getAcceptList({ fileTypes })
  const acceptString = acceptList.join(',')

  const onChange = (event) => {
    const fileList = []
    for (const file of inputElement.current.files) {
      const { name, type } = file

      const mimetype = mime.lookup(name)
      if (mimetype !== type) {
        console.warn(`FileInput: extracted mimetype does not match file mimetype: ${mimetype}, ${type}`)
        continue
      }

      if (!acceptList.includes(mimetype)) {
        console.warn(`FileInput: extracted mimetype is not in the acceptList: ${mimetype}`)
        continue
      }

      fileList.push([uniqid(), file])
    }

    const changeFiles = new Map(fileList)
    setFiles(changeFiles)
    changeHandler(event, change, changeFiles)
  }

  return (
    <div className={containerClassName}>
      <label htmlFor={name} className={labelClassName}>
        <Button type='button' level='secondary'>Browse ...</Button>
        <FileInputListContainer files={files} showFileList={showFileList} />
      </label>
      <input className={inputClassName} disabled={disabled} id={name} name={name} onChange={onChange} required={required} type='file' accept={acceptString} multiple={multiple} ref={inputElement} />
    </div>
  )
}

FileInput.propTypes = {
  change: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  fileTypes: PropTypes.arrayOf(
    PropTypes.oneOf(fileTypesList)
  ).isRequired,
  showFileList: PropTypes.bool
}

export default FileInput
