import React from 'react'
import PropTypes from 'prop-types'
import MarkdownStandard from '../../UIComponents/MarkdownStandard'

import './ResourceDownloads.scss'

function ResourceDownloads ({ title, downloads }) {
  const renderedDownloads = downloads.map(({ asset, cta }, index) =>
    <li key={index} className='resource-download-list-item'>
      <a href={asset} target='_blank' rel='noopener noreferrer' className='resource-download-list-item-link'>{cta || asset}</a>
    </li>
  )
  return (
    <section className='resource-downloads'>
      <header className='resource-downloads-title-container'>
        <MarkdownStandard>{title}</MarkdownStandard>
      </header>
      <ul className='resource-downloads-list'>
        {renderedDownloads}
      </ul>
    </section>
  )
}

ResourceDownloads.propTypes = {
  business: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  downloads: PropTypes.arrayOf(PropTypes.shape({
    asset: PropTypes.string.isRequired,
    cta: PropTypes.string
  }))
}

export default ResourceDownloads
