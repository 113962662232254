import React, { useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import ContentCard from '../../UIComponents/ContentCard'
import FilterButton from '../../UIComponents/FilterButton'
import Loader from '../../UIComponents/Loader'
import RecommendationPanel from '../../UIComponents/RecommendationPanel'
import Search from '../../UIComponents/Search'
import UIAlert from '../../UIComponents/UIAlert'

import { actions, selectors, store } from '../../store'
import { useDebounce } from '../../utils/hooks'

import './ResourcesList.scss'

export const delay = 500

function ResourcesList ({ resources, recommended, search = '', title = '', searchAllowed = true }) {
  const context = useContext(store)
  const { dispatch } = context
  const { business } = selectors.business(context)
  const { pathname } = useLocation()
  const [loading, setLoading] = useState(false)

  const [recommendedValue, setRecommendedValue] = useState(recommended)
  const [searchValue, setSearchValue] = useState(search)
  const [filteredResourceItems, setFilteredResourceItems] = useState([])

  const resourceItems = resources.map(({ path: link, title: resourceTitle, image, time, publishedDate, lastEditedDate, contentType }, index) => (
    <ContentCard
      type={contentType}
      variant='normal'
      image={image}
      link={link}
      publicationDate={publishedDate}
      editedDate={lastEditedDate}
      time={time}
      title={resourceTitle}
      key={index}
      isShowTitle={false}
    />
  ))

  const debouncedSearchValue = useDebounce(searchValue, delay)

  useEffect(() => {
    async function action () {
      setLoading(true)
      await actions.resourcesListUpdate({
        business,
        dispatch,
        pathname,
        search: '',
        recommended: recommendedValue
      })
      setLoading(false)
    }

    if (debouncedSearchValue && recommendedValue) {
      setRecommendedValue(false)
    }

    // Only run this when there's a change
    if (recommendedValue !== recommended) {
      action()
    }

    let filteredResourceItems = []

    if (debouncedSearchValue) {
      filteredResourceItems = resources.filter((item) => {
        return (item.title.toLowerCase().includes(debouncedSearchValue.toLowerCase()) || item.body.toLowerCase().includes(debouncedSearchValue.toLowerCase()))
      }).map(({ path: link, title: resourceTitle, image, time, publishedDate, lastEditedDate, contentType }, index) => (
        <ContentCard
          type={contentType}
          variant='normal'
          image={image}
          link={link}
          publicationDate={publishedDate}
          editedDate={lastEditedDate}
          time={time}
          title={resourceTitle}
          key={index}
          isShowTitle={false}
        />
      ))

      setFilteredResourceItems(filteredResourceItems)
    }
  }, [recommended, recommendedValue, search, debouncedSearchValue, business, dispatch, pathname, resources])

  const header = (
    <div className='resources-list-filters'>
      <div className='resources-list-filters-buttons'>
        {!debouncedSearchValue && <FilterButton className='resources-list-filters-button' selected={recommendedValue} change={() => setRecommendedValue(true)} value='Show recommended' />}
        {!debouncedSearchValue && <FilterButton className='resources-list-filters-button' selected={!recommendedValue} change={() => setRecommendedValue(false)} value='Show all' />}
      </div>
      {searchAllowed && <Search change={(value) => setSearchValue(value)} submit={(value) => setSearchValue(value)} initialValue={searchValue} className='resources-list-filters-search' />}
    </div>
  )

  let content = resourceItems
  if (debouncedSearchValue && filteredResourceItems.length > 0 && recommendedValue === false) {
    content = filteredResourceItems
  } else if (debouncedSearchValue && filteredResourceItems.length === 0 && recommendedValue === false) {
    content = <div className='resources-list-fallback'>
      <UIAlert level='info' message='No resources items were found.' additional='Try widening your search' icon='search' iconPrefix='fas' />
    </div>
  }
  return (
    <RecommendationPanel title={title} header={header} variant='clear'>
      {content}
      <Loader loading={loading} className='resources-list-loader' />
    </RecommendationPanel>
  )
}

ResourcesList.propTypes = {
  resourceCount: PropTypes.number.isRequired,
  resourceLimit: PropTypes.number.isRequired,
  business: PropTypes.string.isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string.isRequired,
      publishedDate: PropTypes.string.isRequired,
      lastEditedDate: PropTypes.string,
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      contentType: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      sectors: PropTypes.arrayOf(PropTypes.string.isRequired),
      tags: PropTypes.arrayOf(PropTypes.string.isRequired),
      time: PropTypes.number.isRequired
    })
  ).isRequired,
  search: PropTypes.string.isRequired,
  title: PropTypes.string
}

export default ResourcesList
