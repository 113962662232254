import React from 'react'
import PropTypes from 'prop-types'

import MarkdownStandard from '../../UIComponents/MarkdownStandard'
import FileList from '../../UIComponents/FileList'
import { isEmpty } from '../../utils/courseUtil'
import './CourseBodyAssessment.scss'

function CourseBodyAssessment ({ course }) {
  const { assessmentDetails, assessmentMaterials } = course
  let files = []
  let hasFiles = false
  if (!isEmpty(assessmentMaterials)) {
    files = assessmentMaterials.map(({ url: href, originalFilename: name, mimeType: type }) =>
      ({ href, name, type })
    )
    hasFiles = !!files.length
  }

  return (
    <div className='course-body-assessment'>
      <MarkdownStandard className='course-body-assessment-information'>{assessmentDetails}</MarkdownStandard>
      { hasFiles && <FileList files={files} /> }
    </div>
  )
}

CourseBodyAssessment.propTypes = {
  course: PropTypes.shape({
    assessmentDetails: PropTypes.string.isRequired,
    assessmentMaterials: PropTypes.arrayOf(PropTypes.shape({
      mimeType: PropTypes.string.isRequired,
      originalFilename: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }))
  })
}

export default CourseBodyAssessment
