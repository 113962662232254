import React from 'react'
import PropTypes from 'prop-types'

import CourseCard from '../../UIComponents/CourseCard'
import RecommendationPanel from '../../UIComponents/RecommendationPanel'

function CourseBodyRelatedCourses ({ title, size, relatedCourses }) {
  const courseItems = relatedCourses.map((course, index) => <CourseCard variant={size} key={index} {...course} />)

  return (
    <RecommendationPanel title={title} variant='inline'>
      {courseItems}
    </RecommendationPanel>
  )
}

CourseBodyRelatedCourses.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['normal', 'small']).isRequired,
  relatedCourses: PropTypes.arrayOf(PropTypes.shape()).isRequired
}

export default CourseBodyRelatedCourses
