import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'

import './Burger.scss'

function Burger ({ className = '', active = false, toggle = () => {} }) {
  const burgerClassName = `ui-component-burger-button ${className}`.trim()

  const icon = active ? 'times' : 'bars'
  const title = active ? 'Close menu' : 'Open menu'

  return <button type='button' onClick={(event) => toggle(event)} className={burgerClassName}>
    <Icon prefix='fas' icon={icon} title={title} className='ui-component-burger-icon' />
  </button>
}

Burger.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  toggle: PropTypes.func
}

export default Burger
