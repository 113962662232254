import React from 'react'
import PropTypes from 'prop-types'

import Avatar from '../Avatar'

import './Attribution.scss'

function Attribution ({ image, jobTitle, name, size = 'large' }) {
  return (
    <div className='ui-component-attribution'>
      <Avatar className='ui-component-attribution-avatar' image={image} name={name} size={size} />
      <address className='ui-component-attribution-contact'>
        <span className='ui-component-attribution-contact-name'>{name}</span>
        <span className='ui-component-attribution-contact-job-title'>{jobTitle}</span>
      </address>
    </div>
  )
}

Attribution.propTypes = {
  image: PropTypes.string,
  jobTitle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['medium', 'large'])
}

export default Attribution
