import React from 'react'
import PropTypes from 'prop-types'
import kebabCase from 'lodash.kebabcase'

import UIAlert from '../../UIComponents/UIAlert'

import './NotificationAlerts.scss'
import { replaceInstances } from '../../utils/string'

function NotificationAlert ({ data, resourceData }) {
  let { message, additional, icon, iconPrefix, resourceId } = data
  const { registrations } = resourceData

  const iconName = kebabCase(icon.replace(/^fa/i, ''))

  const resource = registrations.find(({ id }) => id === resourceId)
  if (resource) {
    const { course } = resource
    const { session } = course
    const data = { course, session }
    message = replaceInstances({ data, text: message })
    additional = replaceInstances({ data, text: additional })
  }

  return (
    <li className='notification-alerts-list-item'>
      <UIAlert level='info' message={message} additional={additional} icon={iconName} iconPrefix={iconPrefix} />
    </li>
  )
}

function NotificationAlerts ({ notifications, resourceData }) {
  const notificationAlerts = notifications.map((notification, index) => {
    const props = {
      ...notification,
      resourceData
    }
    return <NotificationAlert { ...props } key={index}/>
  })

  return (
    <section className='notification-alerts'>
      <ul className='notification-alerts-list'>
        {notificationAlerts}
      </ul>
    </section>
  )
}

NotificationAlerts.propTypes = {
  business: PropTypes.string.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({
    resource: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
    data: PropTypes.shape({
      resourceId: PropTypes.string.isRequired,
      additional: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      iconPrefix: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired
    }).isRequired
  }).isRequired),
  resourceData: PropTypes.shape({
    registrations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      course: PropTypes.shape({
        title: PropTypes.string.isRequired,
        session: PropTypes.shape({
          id: PropTypes.string.isRequired
        })
      }).isRequired
    }))
  }).isRequired
}

export default NotificationAlerts
