import React from 'react'
import PropTypes from 'prop-types'

import './StandardVideo.scss'

const extractYoutubeId = ({ url }) => {
  const youtubeId = new RegExp('(?:watch?v=)?([a-zA-Z0-9_-]{11})', 'g')
  const videoId = url.match(youtubeId)
  return videoId.pop()
}

function StandardVideo ({ url }) {
  const videoId = extractYoutubeId({ url })
  const src = `https://www.youtube.com/embed/${videoId}`

  return (
    <section className='standard-video'>
      <iframe className='standard-video-frame' title='Standard video' src={src} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
    </section>
  )
}

StandardVideo.propTypes = {
  business: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default StandardVideo
