import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'dwnld-attr-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import history from './history'

import App from './App'

import config from './config'

const { version } = config
console.info(`v${version}`)

const initialState = window.__INITIAL_STATE__
Reflect.deleteProperty(window, '__INITIAL_STATE__')

if (initialState) {
  // hydrate
  ReactDOM.hydrate(
    <React.StrictMode>
      <App initialState={initialState} history={history} />
    </React.StrictMode>,
    document.getElementById('root')
  )
} else {
  // render
  ReactDOM.render(
    <React.StrictMode>
      <App history={history} />
    </React.StrictMode>,
    document.getElementById('root')
  )
}
