export default Object.freeze({
  bffUrl: process.env.REACT_APP_BFF_URL,
  defaultBusiness: process.env.REACT_APP_DEFAULT_BUSINESS,
  survicate: {
    bondSolonWorkspace: process.env.REACT_APP_SURVICATE_WORKSPACE_BONDSOLON
  },
  version: process.env.REACT_APP_VERSION,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  googleAnalytics: {
    debug: process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG === 'True',
    trackingID: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
  },
  totaraCourseUrl: process.env.REACT_APP_TOTARA_COURSE_URL
})
