import React from 'react'
import PropTypes from 'prop-types'

import MarkdownStandard from '../../UIComponents/MarkdownStandard'
import { LinkButton } from '../../UIComponents/Button'

import './CourseBodyPreSaleLink.scss'

function CourseBodyPreSaleLink ({ course: { preSaleLink }, preSaleLinkInformation, linkCta }) {
  return (
    <div className='course-body-pre-sale-link'>
      <MarkdownStandard className='course-body-pre-sale-link-overview-information'>{preSaleLinkInformation}</MarkdownStandard>
      <LinkButton href={preSaleLink}>{linkCta}</LinkButton>
    </div>
  )
}

CourseBodyPreSaleLink.propTypes = {
  course: PropTypes.shape({
    preSaleLink: PropTypes.string.isRequired
  }),
  preSaleLinkInformation: PropTypes.string,
  linkCta: PropTypes.string.isRequired
}

export default CourseBodyPreSaleLink
