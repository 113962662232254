import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { isExternal } from '../../utils/location'

import './Card.scss'

function CardLink ({ link }) {
  if (!link) {
    return null
  }

  const className = 'ui-component-card-link'
  return isExternal(link)
    ? <a href={link} className={className} rel='noopener noreferrer' target='_blank'>View</a>
    : <Link to={link} className={className}>View</Link>
}

function Card ({ children, className = '', link, size }) {
  const classNameCard = `ui-component-card-${size} ${className}`.trim()

  return (
    <div className={classNameCard}>
      {children}
      <CardLink link={link} />
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  link: PropTypes.string,
  size: PropTypes.oneOf(['block', 'large', 'small', 'micro']).isRequired
}

export default Card
