import React from 'react'
import PropTypes from 'prop-types'
import './RecordOfLearningDownload.scss'
import { format } from '../../utils/date'
import { startCase } from 'lodash'
import { CSVDownloadButton } from '../../UIComponents/Button'
function getCoursesFormatedDate ({ courses }) {
  return courses.map(course => {
    const updatedCourse = { ...course, format: startCase(course.format), completionDate: format({ date: `${course.completionDate}`, preset: 'short' }) }
    return updatedCourse
  })
}
function RecordOfLearningDownload ({ title, recordOfLearningDownloadButtonText, courses }) {
  const headers = [
    { label: 'Course', key: 'title' },
    { label: 'Format', key: 'format' },
    { label: 'Learning Hours', key: 'cpdHours' },
    { label: 'Course Status', key: 'status' },
    { label: 'Completion Date', key: 'completionDate' }
  ]
  const formatedCourses = getCoursesFormatedDate({ courses: courses })
  return (
    <section className='record-of-learning-download'>
      <div className='record-of-learning-download-container'>
        <div><h2 className='record-of-learning-download-title'>{title}</h2></div>
        <CSVDownloadButton fileName={ `${title}-Courses.csv` } data= { formatedCourses } headers= { headers }>{ recordOfLearningDownloadButtonText } </CSVDownloadButton>
      </div>
      <hr></hr>
    </section>
  )
}
RecordOfLearningDownload.propTypes = {
  title: PropTypes.string.isRequired,
  recordOfLearningDownloadButtonText: PropTypes.string.isRequired,
  downloadableCourses: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    salesForceCourseEventID: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    format: PropTypes.string,
    cpdHours: PropTypes.number,
    status: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    completionDate: PropTypes.string
  }))
}
export default RecordOfLearningDownload
