import React from 'react'
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'

import './Pagination.scss'

function Pagination ({ previousLabel, nextLabel, pageCount, onPageChange, currentPageNumber }) {
  return (
    <>
      <ReactPaginate previousLabel={previousLabel}
        nextLabel={nextLabel}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={12}
        onPageChange={onPageChange}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        forcePage={currentPageNumber}/>
    </>
  )
}

Pagination.propTypes = {
  previousLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  breakLabel: PropTypes.string,
  breakClassName: PropTypes.string,
  pageCount: PropTypes.number,
  marginPagesDisplayed: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  onPageChange: PropTypes.func,
  containerClassName: PropTypes.string,
  subContainerClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  forcePage: PropTypes.number
}

export default Pagination
