import React from 'react'
import PropTypes from 'prop-types'
import MarkdownStandard from '../../UIComponents/MarkdownStandard'

import './StandardContent.scss'

function StandardContent ({ text, style }) {
  const className = `standard-content ${style}`.trim()
  return (
    <section className={className}>
      <MarkdownStandard>{text}</MarkdownStandard>
    </section>
  )
}

StandardContent.propTypes = {
  business: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default StandardContent
