import { IOError, ValidationError } from '@wss-dh/wss.web.package.errors'
import kebabCase from 'lodash.kebabcase'
import 'whatwg-fetch'

import config from '../config'
import { searchString } from '../utils/location'

const { bffUrl } = config

export async function getPageData ({ business, pathname, params, search = '' }) {
  const requestOptions = {
    credentials: 'include',
    mode: 'cors',
    method: 'GET'
  }
  const businessParam = kebabCase(business)

  const uids = pathname.split('/').filter(uid => Boolean(uid))
  const searchParams = searchString({ ...params, uids })
  const additionalSearch = search.replace(/^\?/, '')

  const query = [searchParams, additionalSearch].filter(search => Boolean(search)).join('&')
  const requestUrl = `${bffUrl}/pages/${businessParam}?${query}`

  let response
  try {
    response = await window.fetch(requestUrl, requestOptions)
  } catch (error) {
    throw new IOError(`utils/api.getPageData ${requestUrl}`, { origError: error, statusCode: error.statusCode })
  }

  if (!response.ok) {
    if (response.status === 404) {
      return getPageData({ business, pathname: '/404', search: '' })
    }

    throw new IOError(`utils/api.getPageData ${requestUrl}`, { statusCode: response.status })
  }

  let result
  try {
    result = await response.json()
  } catch (error) {
    throw new ValidationError(`utils/api.getPageData ${requestUrl}`, { origErrors: [error] })
  }

  return result
}

const widgetBackendDefaultRequestOptions = {
  credentials: 'include',
  mode: 'cors',
  method: 'POST'
}

async function widgetBackendPost ({ backend, business, requestOptions }) {
  const businessParam = kebabCase(business)
  const requestUrl = `${bffUrl}/widgets/${businessParam}/${backend}`

  let response
  try {
    response = await window.fetch(requestUrl, requestOptions)
  } catch (error) {
    throw new IOError(`utils/api.widgetBackendPost ${requestUrl}`, { origError: error, statusCode: error.statusCode })
  }

  if (!response.ok) {
    throw new IOError(`utils/api.widgetBackendPost ${requestUrl}`, { statusCode: response.status })
  }

  let result
  try {
    result = await response.json()
  } catch (error) {
    throw new ValidationError(`utils/api.widgetBackendPost ${requestUrl}`, { origErrors: [error] })
  }

  return result
}

export function widgetBackendPostJSON ({ backend, business, body }) {
  const requestOptions = {
    ...widgetBackendDefaultRequestOptions,
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    body: JSON.stringify(body)
  }
  return widgetBackendPost({ backend, business, requestOptions })
}

export function widgetBackendPostForm ({ backend, business, body, file }) {
  const data = new FormData()
  Object.entries(body).forEach(([key, value]) =>
    data.append(key, value)
  )
  const { name } = file
  data.append('file', file, name)

  const requestOptions = {
    ...widgetBackendDefaultRequestOptions,
    body: data
  }
  return widgetBackendPost({ backend, business, requestOptions })
}
