import React from 'react'
import PropTypes from 'prop-types'

import { openWindow } from '../../utils/location'
import { IconNew } from '../Icon'
import { CSVLink } from 'react-csv'
import { saveAs } from 'file-saver'

import './Button.scss'

function openLink ({ disabled = false, event, href }) {
  event.preventDefault()
  if (!disabled) {
    openWindow(href)
  }
}

export function LinkButton ({ children, disabled = false, href, level = 'primary', download }) {
  const disabledClassName = disabled ? 'ui-component-button-link-disabled' : ''
  const className = `ui-component-button-link-${level} ${disabledClassName}`.trim()
  return download
    ? <a className={className} href={href} download={download}>{children}</a>
    : <a className={className} href={href} onClick={(event) => openLink({ disabled, event, href })}>{children}</a>
}

LinkButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  download: PropTypes.string,
  href: PropTypes.string.isRequired,
  level: PropTypes.string
}

export function SimpleLinkButton ({ children, href, download, iconName, iconTitle = '' }) {
  return download
    ? <a className='ui-component-simple-button-link' href={href} download={download}>
      {iconName ? <IconNew icon={iconName} title={iconTitle} /> : ''}{children}
    </a>
    : <a className='ui-component-simple-button-link' href={href} onClick={(event) => openLink({ event, href })}>
      {iconName ? <IconNew icon={iconName} title={iconTitle} /> : ''}{children}
    </a>
}

export function DownloadImageButtom ({ children, url, name, disabled = false, level = 'primary' }) {
  const disabledClassName = disabled ? 'ui-component-button-disabled' : ''
  const className = `ui-component-button-${level} ${disabledClassName}`.trim()
  return <button className={className} onClick={(event) => {
    saveAs(url, name)
  }
  }>{children}</button>
}
DownloadImageButtom.prototype = {
  children: PropTypes.node,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  level: PropTypes.string
}
SimpleLinkButton.propTypes = {
  children: PropTypes.node,
  download: PropTypes.string,
  href: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  iconTitle: PropTypes.string
}

export function CSVDownloadButton ({ children, clickHanlder = () => { }, data, headers, level = 'primary', disabled = false, fileName = 'download.csv' }) {
  const disabledClassName = disabled ? 'ui-component-button-disabled' : ''
  const className = `ui-component-button-${level} ${disabledClassName}`.trim()
  return <CSVLink className={className} data={data} onClick={clickHanlder} filename={fileName} headers={headers}>{children}</CSVLink>
}

CSVDownloadButton.propTypes = {
  children: PropTypes.node,
  fileName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired
  })).isRequired,
  level: PropTypes.string,
  disabled: PropTypes.bool,
  clickHanlder: PropTypes.func
}

function Button ({ children, clickHandler = () => { }, disabled = false, level = 'primary', type }) {
  const disabledClassName = disabled ? 'ui-component-button-disabled' : ''
  const className = `ui-component-button-${level} ${disabledClassName}`.trim()
  return <button className={className} type={type} onClick={clickHandler}>{children}</button>
}

Button.propTypes = {
  children: PropTypes.node,
  clickHandler: PropTypes.func,
  disabled: PropTypes.bool,
  level: PropTypes.string,
  type: PropTypes.string.isRequired
}

export default Button
