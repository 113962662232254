import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import { selectors, store } from '../../store'

import config from '../../config'

import Layouts from '../../Layouts'

import GoogleAnalytics from '../../UIComponents/GoogleAnalytics'
import Loader from '../../UIComponents/Loader'
import Survicate from '../../UIComponents/Survicate'

import './BondSolon.scss'

library.add(fas, far)

const {
  survicate: { bondSolonWorkspace },
  googleAnalytics: { debug, trackingID }
} = config

export default function BondSolon ({ business, ...props }) {
  const { state } = useContext(store)
  const { model: { meta: { description, keywords }, language, title } } = props
  const loading = selectors.routePending({ state })

  return (
    <Route path='*'>
      <Helmet>
        <html lang={language} />
        <title>{title}</title>
        <meta charSet='utf-8' />
        <meta name='description' content={description} />
        <meta name='keywords' content={keywords} />
        <meta property='og:site_name' content='Bond Solon' />
        <meta name='twitter:site' content='Bond Solon' />
      </Helmet>
      <div className='business-container bond-solon'>
        <Layouts {...props} />
        <Survicate workspace={bondSolonWorkspace} />
        <Loader loading={loading} size='modal' />
      </div>
      <GoogleAnalytics debug={debug} trackingID={trackingID} />
    </Route>
  )
}
