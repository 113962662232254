import config from '../config'

const {
  totaraCourseUrl
} = config

export function getCourseLink ({ path, salesForceCourseEventID, type, lmsCourseID }) {
  console.log('getCourseLink attributes ::', type, lmsCourseID, totaraCourseUrl)
  // TODO: this root(/courses) needs to be dynamic. Util file can be removed once root fetched from CMS
  const root = 'courses'
  let link
  switch (type) {
    case 'Online Learning':
      link = `${totaraCourseUrl}${lmsCourseID}`
      break
    default:
      link = salesForceCourseEventID ? `/${root}/${path}/${salesForceCourseEventID}` : `/${root}/${path}`
  }
  console.log('final url: ', link)
  return link
}

export function isEmpty (value) {
  return (
    // null or undefined
    (value == null) ||

    // has length and it's zero
    (Object.prototype.hasOwnProperty.call(value, 'length') && value.length === 0) ||

    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  )
}
