import React from 'react'

import BoxContentLinks from './BoxContentLinks'
import Breadcrumbs from './Breadcrumbs'
import CourseBody from './CourseBody'
import CourseBodyContact from './CourseBodyContact'
import CourseBodyIntroduction from './CourseBodyIntroduction'
import CourseBodyMaterials from './CourseBodyMaterials'
import CourseBodyAssessment from './CourseBodyAssessment'
import CourseBodyPreCourseworkInformation from './CourseBodyPreCourseworkInformation'
import CourseBodyPreSaleInformation from './CourseBodyPreSaleInformation'
import CourseBodyPreSaleLink from './CourseBodyPreSaleLink'
import CourseBodyRelatedCourses from './CourseBodyRelatedCourses'
import CourseBodyRelatedResources from './CourseBodyRelatedResources'
import CourseEventBody from './CourseEventBody'
import CourseEventBodyAlert from './CourseEventBodyAlert'
import CourseEventBodyInformation from './CourseEventBodyInformation'
import CourseEventBodyTrainers from './CourseEventBodyTrainers'
import CourseEventDocumentUpload from './CourseEventDocumentUpload'
import CourseHeader from './CourseHeader'
import EventAdvertisement from './EventAdvertisement'
import FeatureButtonLink from './FeatureButtonLink'
import FooterInternalLinks from './FooterInternalLinks'
import FooterExternalLinks from './FooterExternalLinks'
import FooterAddress from './FooterAddress'
import FooterContact from './FooterContact'
import HeaderNavigation from './HeaderNavigation'
import HeaderTitle from './HeaderTitle'
import HeroSlider from './HeroSlider'
import IconContent from './IconContent'
import LatestNews from './LatestNews'
import MyCoursesSummary from './MyCoursesSummary'
import MyProgrammesSummary from './MyProgrammesSummary'
import RecordOfLearningCourseEventsSummary from './RecordOfLearningCourseEventsSummary'
import RecordOfLearningProgrammesSummary from './RecordOfLearningProgrammesSummary'
import RecordOfLearningTotalLearningHours from './RecordOfLearningTotalLearningHours'
import RecordOfLearningDownload from './RecordOfLearningDownload'
import NewsArticleBody from './NewsArticleBody'
import NewsArticleHeader from './NewsArticleHeader'
import NewsList from './NewsList'
import NotificationAlerts from './NotificationAlerts'
import PersonalisedGreeting from './PersonalisedGreeting'
import RecommendedCoursesSummary from './RecommendedCoursesSummary'
import RecommendedNewsSummary from './RecommendedNewsSummary'
import RecommendedResourcesSummary from './RecommendedResourcesSummary'
import ResourceBody from './ResourceBody'
import ResourceDownloads from './ResourceDownloads'
import ResourceHeader from './ResourceHeader'
import ResourcesList from './ResourcesList'
import SidebarNavigation from './SidebarNavigation'
import StandardContent from './StandardContent'
import StandardImage from './StandardImage'
import StandardVideo from './StandardVideo'
import SSOSignIn from './SSOSignIn'
import SSOSignOut from './SSOSignOut'
import SSOCallback from './SSOCallback'

import WidgetError from './WidgetError'

const widgetRegistry = new Map([
  ['breadcrumbs', Breadcrumbs],
  ['boxContentLinks', BoxContentLinks],
  ['courseBody', CourseBody],
  ['courseBodyContact', CourseBodyContact],
  ['courseBodyIntroduction', CourseBodyIntroduction],
  ['courseBodyMaterials', CourseBodyMaterials],
  ['courseBodyAssessment', CourseBodyAssessment],
  ['courseBodyPreCourseworkInformation', CourseBodyPreCourseworkInformation],
  ['courseBodyPreSaleInformation', CourseBodyPreSaleInformation],
  ['courseBodyPreSaleLink', CourseBodyPreSaleLink],
  ['courseBodyRelatedCourses', CourseBodyRelatedCourses],
  ['courseBodyRelatedResources', CourseBodyRelatedResources],
  ['courseEventBody', CourseEventBody],
  ['courseEventBodyAlert', CourseEventBodyAlert],
  ['courseEventBodyInformation', CourseEventBodyInformation],
  ['courseEventBodyTrainers', CourseEventBodyTrainers],
  ['courseEventDocumentUpload', CourseEventDocumentUpload],
  ['courseHeader', CourseHeader],
  ['eventAdvertisement', EventAdvertisement],
  ['featureButtonLink', FeatureButtonLink],
  ['footerExternalLinks', FooterExternalLinks],
  ['footerInternalLinks', FooterInternalLinks],
  ['footerContact', FooterContact],
  ['footerAddress', FooterAddress],
  ['headerNavigation', HeaderNavigation],
  ['headerTitle', HeaderTitle],
  ['heroSlider', HeroSlider],
  ['iconContent', IconContent],
  ['latestNews', LatestNews],
  ['myCoursesSummary', MyCoursesSummary],
  ['myProgrammesSummary', MyProgrammesSummary],
  ['recordOfLearningCourseEventsSummary', RecordOfLearningCourseEventsSummary],
  ['recordOfLearningTotalLearningHours', RecordOfLearningTotalLearningHours],
  ['recordOfLearningDownload', RecordOfLearningDownload],
  ['recordOfLearningProgrammesSummary', RecordOfLearningProgrammesSummary],
  ['newsArticleBody', NewsArticleBody],
  ['newsArticleHeader', NewsArticleHeader],
  ['newsList', NewsList],
  ['notificationAlerts', NotificationAlerts],
  ['personalisedGreeting', PersonalisedGreeting],
  ['recommendedCoursesSummary', RecommendedCoursesSummary],
  ['recommendedNewsSummary', RecommendedNewsSummary],
  ['recommendedResourcesSummary', RecommendedResourcesSummary],
  ['resourceBody', ResourceBody],
  ['resourceDownloads', ResourceDownloads],
  ['resourceHeader', ResourceHeader],
  ['resourcesList', ResourcesList],
  ['sidebarNavigation', SidebarNavigation],
  ['standardContent', StandardContent],
  ['standardImage', StandardImage],
  ['standardVideo', StandardVideo],
  ['ssoSignIn', SSOSignIn],
  ['ssoSignOut', SSOSignOut],
  ['ssoCallback', SSOCallback]
])

const logger = console

export function Widgets ({ widgets = [] }) {
  return widgets.map((component, index) => <Widget {...component} key={index} />)
}

function Widget ({ backend, business, name, model, error }) {
  if (!widgetRegistry.has(name)) {
    logger.warn('Unknown component', name)
    return null
  }

  if (error) {
    return <WidgetError name={name} error={error} />
  }

  const props = { backend, business, ...model }
  const WidgetComponent = widgetRegistry.get(name)
  return <WidgetComponent {...props} />
}

export default Widget
