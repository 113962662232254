import React from 'react'
import PropTypes from 'prop-types'

import MarkdownStandard from '../../UIComponents/MarkdownStandard'

function CourseEventBodyTrainers ({ additionalInformation = '' }) {
  return (
    <div className='course-event-body-trainers'>
      <MarkdownStandard className='course-event-body-trainers-information'>{additionalInformation}</MarkdownStandard>
    </div>
  )
}

CourseEventBodyTrainers.propTypes = {
  additionalInformation: PropTypes.string
}

export default CourseEventBodyTrainers
