export function business ({ state = {} }) {
  const { business = null } = state
  return { business }
}

export function courseEventDocuments ({ state = {}, courseId, sessionId }) {
  if (!state) {
    return []
  }
  const { courseEventDocuments = new Map() } = state
  return courseEventDocuments.get(`${courseId}.${sessionId}`) || []
}

export function currentCustomer ({ state = {} }) {
  if (!state) {
    return null
  }
  const { model: { currentCustomer = null } = {} } = state
  return currentCustomer
}

export function currentCustomersalesForceContactID ({ state = {} }) {
  const customer = currentCustomer({ state })
  if (!customer) {
    return null
  }
  const { salesForceContactID = null } = customer
  return salesForceContactID
}

export function currentCustomerSurveyCompletions ({ state = {} }) {
  const customer = currentCustomer({ state })
  if (!customer) {
    return null
  }
  const { surveyCompletions = [] } = customer
  return surveyCompletions
}

export function documentUpload ({ state = {} }) {
  const { documentUpload = new Map() } = state
  return documentUpload
}

export function documentUploadStatus ({ state = {}, localId }) {
  const { documentUpload = new Map() } = state
  if (!localId || !documentUpload.has(localId)) {
    return
  }

  return documentUpload.get(localId)
}

export function modal ({ state }) {
  const { modal: { active = false } = {} } = state
  return { active }
}

export function profileMenuVisible ({ state }) {
  const { profileMenu: { visible = false } = {} } = state
  return visible
}

export function sideBarMenuVisible ({ state }) {
  const { sideBarMenu: { visible = false } = {} } = state
  return visible
}

export function routePending ({ state }) {
  const { routePending = false } = state
  return routePending
}

export function state ({ state }) {
  return { state }
}

export function ssoTokenExchange ({ state }) {
  const { ssoTokenExchange: { ok, error = null, redirect = null } = {} } = state
  return { ok, error, redirect }
}

export function ssoSignOut ({ state }) {
  const { ssoSignOut: { ok, error = null } = {} } = state
  return { ok, error }
}
