import React from 'react'
import PropTypes from 'prop-types'

import './RecommendationPanel.scss'

function RecommendationPanel ({ title, children, className = '', header = null, variant = 'normal', endToEnd, customClass = '' }) {
  const classNamePanel = customClass ? `ui-component-recommendation-panel-${variant}-${customClass}`.trim() : `ui-component-recommendation-panel-${variant} ${className}`.trim()
  return (
    <section className={classNamePanel} data-endtoend={endToEnd}>
      <div className='ui-component-recommendation-panel-content'>
        <h2 className='ui-component-recommendation-panel-content-title'>{title}</h2>
        {header && <div className='ui-component-recommendation-panel-content-header'>{header}</div>}
        <div className='ui-component-recommendation-panel-content-children'>
          {children}
        </div>
      </div>
    </section>
  )
}

RecommendationPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  header: PropTypes.node,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['clear', 'container', 'normal', 'inline']),
  endToEnd: PropTypes.string
}

export default RecommendationPanel
