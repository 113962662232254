import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import BondSolon from './BondSolon'

import Loader from '../UIComponents/Loader'

import { actions, selectors, store } from '../store'
import { useLocationChange } from '../utils/hooks'

import config from '../config'
const { defaultBusiness } = config

const businessRegistry = new Map([
  ['bondSolon', BondSolon]
])

function Businesses () {
  const { dispatch, state } = useContext(store)
  const location = useLocation()

  function emptyState () {
    if (state) {
      return
    }

    console.warn('No state exists')
    const { pathname, search } = location
    actions.fetchPage({ dispatch, pathname, search, business: defaultBusiness })
  }

  function routeUpdate () {
    if (!state) {
      console.warn('No state exists')
      return
    }

    const { business } = selectors.business({ state })
    const { pathname, search } = location
    actions.fetchPage({ dispatch, pathname, search, business })
  }

  function resetScroll () {
    if (window) {
      window.scrollTo(0, 0)
    }
  }

  useEffect(() => emptyState())
  useLocationChange(() => routeUpdate())
  useLocationChange(() => resetScroll())

  if (state === null) {
    console.warn('NO STATE, RENDERING LOADING')
    return <Loader loading />
  }

  const { business } = selectors.business({ state })
  if (!businessRegistry.has(business)) {
    console.warn('Unknown business', business)
    return null
  }

  const BusinessComponent = businessRegistry.get(business)
  return <BusinessComponent {...state} />
}

export default Businesses
