import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Containers } from '../../Containers'

import LogoBondSolon from '../../UIComponents/LogoBondSolon'

import './Marigold.scss'

function renderLogo ({ business }) {
  switch (business) {
    case 'ica':
      return null
    case 'bondSolon':
    default:
      return <LogoBondSolon className='marigold-header-logo-icon' />
  }
}

function Marigold ({ business, model }) {
  const { containers } = model
  const logo = renderLogo({ business })

  return (
    <div className='marigold'>
      <div className='marigold-header'>
        <div className='marigold-header-content'>
          <Link className='marigold-header-logo' to='/'>{logo}</Link>
        </div>
      </div>
      <div className='marigold-content'>
        <Containers containers={containers} />
      </div>
    </div>
  )
}

Marigold.propTypes = {
  model: PropTypes.shape({
    containers: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired
      })
    ).isRequired,
    variation: PropTypes.string,
    backgroundImage: PropTypes.string
  }).isRequired
}

export default Marigold
