import React from 'react'
import PropTypes from 'prop-types'

import ContentCard from '../../UIComponents/ContentCard'
import RecommendationPanel from '../../UIComponents/RecommendationPanel'

function CourseBodyRelatedResources ({ title, resourceLimit, size, relatedResources }) {
  const resourceItems = relatedResources.slice(0, 3) // Temporary for now
    .map(({ path: link, title: newsTitle, publishedDate, lastEditedDate, image, time, contentType }, index) => (
      <ContentCard
        variant={size}
        type={contentType}
        image={image}
        link={link}
        publicationDate={publishedDate}
        editedDate={lastEditedDate}
        time={time}
        title={newsTitle}
        key={index}
      />
    ))

  return (
    <RecommendationPanel title={title} variant='clear'>
      {resourceItems}
    </RecommendationPanel>
  )
}

CourseBodyRelatedResources.propTypes = {
  title: PropTypes.string.isRequired,
  resourceLimit: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['normal', 'small']).isRequired,
  relatedResources: PropTypes.arrayOf(PropTypes.shape()).isRequired
}

export default CourseBodyRelatedResources
