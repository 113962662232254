import { format as dateFnsFormat, parseISO } from 'date-fns'

const defaultFormat = 'EEEE do MMMM y'
const shortFormat = 'dd/MM/yyy'
const timeFormat = 'HH:mm'

const formats = new Map([
  ['default', defaultFormat],
  ['short', shortFormat],
  ['time', timeFormat],
  ['dateTime', `${defaultFormat}, ${timeFormat}`],
  ['dateTimeShort', `${shortFormat}, ${timeFormat}`]
])
function parseDate (date) {
  if (date instanceof String) {
    return parseISO(date)
  } else if (date instanceof Date) {
    return parseISO(date.toISOString())
  } else {
    return parseISO(date)
  }
}
export function format ({ date, preset = 'default' }) {
  return date ? dateFnsFormat(parseDate(date), formats.get(preset)) : ''
}

export function today ({ preset = 'default' } = {}) {
  const today = new Date(Date.now())
  return dateFnsFormat(parseDate(today), formats.get(preset))
}
