import React from 'react'
import PropTypes from 'prop-types'

import MarkdownStandard from '../../UIComponents/MarkdownStandard'
import ViewLink from '../../UIComponents/ViewLink'
import Card from '../../UIComponents/Card'

import { format } from '../../utils/date'

import './EventAdvertisement.scss'

function getStyle ({ url } = {}) {
  const backgroundImage = url ? `url(${url})` : 'none'
  return { backgroundImage }
}

function EventAdvertisement ({ business, name, title, eventDate: date, content, link, linkText, backgroundImage }) {
  const imageStyle = getStyle(backgroundImage)
  const eventDate = date ? format({ date }) : undefined
  return (
    <section className='event-advertisement'>
      <Card className='ui-component-content-card-micro' size='block' link={link}>
        <div className='event-advertisement-image' style={imageStyle} />
        <div className='event-advertisement-content'>
          <div className='event-advertisement-content-header'>
            <h3 className='event-advertisement-content-title'>{title}</h3>
            {/* made eventDate optional */}
            { eventDate ? <span className='event-advertisement-content-date'>{eventDate}</span> : '' }
          </div>
          <div className='event-advertisement-content-copy'>
            <MarkdownStandard className='event-advertisement-content-content'>{content}</MarkdownStandard>
            <ViewLink link={link} text={linkText} variant='inherit' />
          </div>
        </div>
      </Card>
    </section>
  )
}

EventAdvertisement.propTypes = {
  business: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  eventDate: PropTypes.string,
  content: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  backgroundImage: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
}

export default EventAdvertisement
