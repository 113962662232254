import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import kebabCase from 'lodash.kebabcase'

import './Icon.scss'

function Icon ({ className = '', icon, prefix, title }) {
  const iconClassName = `ui-icon ${className}`.trim()
  return <FontAwesomeIcon className={iconClassName} icon={[prefix, icon]} title={title} />
}

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  prefix: PropTypes.oneOf(['fas', 'far', 'fa']).isRequired,
  title: PropTypes.string.isRequired
}

export default Icon

export function IconNew ({ className, icon, title }) {
  const iconClassName = `ui-icon ${className}`.trim()
  const iconName = kebabCase(icon.replace(/^fa/i, ''))
  return <FontAwesomeIcon className={iconClassName} icon={iconName} title={title} />
}

IconNew.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

const fileIconTypes = new Map([
  ['application/pdf', 'FaFilePdf'],
  ['text/csv', 'FaFileExcel'],
  ['application/msword', 'FaFileWord'],
  ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'FaFileWord'],
  ['application/vnd.ms-excel', 'FaFileExcel'],
  ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'FaFileExcel']
])

const fileIconFallback = 'FaFile'

export function FileIcon ({ className, type, title }) {
  const icon = fileIconTypes.get(type) || fileIconFallback
  return <IconNew icon={icon} className={className} title={title} />
}

FileIcon.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}
