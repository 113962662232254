import React from 'react'
import PropTypes from 'prop-types'

import FileList from '../../UIComponents/FileList'
import MarkdownStandard from '../../UIComponents/MarkdownStandard'

import './CourseBodyMaterials.scss'

function CourseBodyMaterials ({ course }) {
  const { courseMaterials, courseMaterialInformation } = course
  let files = []
  let hasFiles = false
  if (courseMaterials.length) {
    files = courseMaterials.map(({ file: { url: href, originalFilename: name, mimeType: type } }) =>
      ({ href, name, type })
    )
    hasFiles = !!files.length
  }

  return (
    <div className='course-body-materials'>
      <MarkdownStandard className='course-body-materials-information'>{courseMaterialInformation}</MarkdownStandard>
      { hasFiles && <FileList files={files} /> }
    </div>
  )
}

CourseBodyMaterials.propTypes = {
  course: PropTypes.shape({
    courseMaterialInformation: PropTypes.string,
    courseMaterials: PropTypes.arrayOf(PropTypes.shape({
      file: PropTypes.shape({
        mimeType: PropTypes.string.isRequired,
        originalFilename: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      }).isRequired,
      tags: PropTypes.arrayOf(PropTypes.string)
    }))
  })
}

export default CourseBodyMaterials
