import React, { useContext, useEffect } from 'react'

import { actions, store } from '../../store'

import './Modal.scss'

function Modal ({ active, children }) {
  const { dispatch } = useContext(store)

  useEffect(() => actions.modal({ dispatch, active }), [active, dispatch])

  const className = active ? 'ui-component-modal-active' : 'ui-component-modal'

  return (
    <div className={className}>
      <div className='modal-children'>
        {children}
      </div>
    </div>
  )
}

export default Modal
